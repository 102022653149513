import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from '../layouts/DashboardLayout';
import MainLayout from '../layouts/MainLayout';

import DashboardView from '../views/dashboard/UserView';
import NotFoundView from '../views/errors/NotFoundView';
import Web from '../views/page';
import AccountView from '../views/account/AccountView';
import PropertyListView from '../views/properties/UserProperties';
import SavedPropertyListView from '../views/properties/SavedProperties';
import CalendarView from '../views/calendar/CalendarView';
import MessageView from '../views/message/MessageView';

// import SettingsView from '../views/settings/SettingsView';
// import FollowEAS from '../views/follow/FollowEAS';
// import MyRobots from '../views/robots/MyRobots';
// import ProductListView from '../views/product/ProductListView';
// import SettingsView from '../views/settings/SettingsView';
// import OpenOrders from '../views/orders/OpenOrders';
// import TransactionsListView from '../views/transactions/TransactionsListView';
// import ByMagicNumber from '../views/transactions/TransactionsByMagicNumber';
// import Projections from '../views/projections/FollowEAS';


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'properties', element: <PropertyListView /> },
      { path: 'savedproperties', element: <SavedPropertyListView /> },
      { path: 'account', element: <AccountView /> },  
      { path: 'calendar', element: <CalendarView /> },
      { path: 'message', element: <MessageView /> },
      // { path: 'settings', element: <SettingsView /> },
      // { path: 'follow', element: <FollowEAS /> },
      // { path: 'robots', element: <MyRobots /> },
      // { path: 'account', element: <AccountView /> },
      // { path: 'products', element: <ProductListView /> },
      // { path: 'settings', element: <SettingsView /> },
      // { path: 'transactions', element: <TransactionsListView /> },
      // { path: 'statistics', element: <ByMagicNumber /> },
      // { path: 'orders', element: <OpenOrders /> },

    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'web', element: <Web /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: 'login', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '404', element: <NotFoundView /> },
    ]
  }
];

export default routes;
