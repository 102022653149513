import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import GoogleMapReact from 'google-map-react';

import axios from 'axios';
import pageData from '../../../pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

import Pointer from './Pointer';
import PropertyValue from './PropertyValue';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonClose: {
    color: '#b71c1c',
    background: '#fce4ec',
    marginLeft: theme.spacing(1)
  },
  buttonSave: {
    color: '#0d47a1',
    background: '#e3f2fd',
    marginLeft: theme.spacing(1)
  },
}));

const PropertyDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [property, setProperty] = useState({});
  const [propertyAddress, setPropertyAddress] = useState('');
  const [streetViewImage, setStreetViewImage] = useState('');
  const [location, setLocation] = useState({});
  const [lastSale, setLastSale] = useState(0);
  const [lienAmount, setLienAmount] = useState(0);

  const [values, setValues] = useState({
    beds: '',
    baths: '',
    yearBuild: '',
    propertyType: '',
    ownerOccupied: '',
    sqft: '',
    lotSize: '',
    estValue: '',
    lienAmount: '',
    remainingBalance: '',
    owner1FirstName: '',
    owner1LastName: '',
    owner2FirstName: '',
    owner2LastName: '',
    companyOwner: '',
    differentMailingAddress: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const fetchProperties = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-property-by-id/${rest.property}`)
      .then((res) => {
        if (res.status === 200) {
          setProperty(res.data.property);
          setPropertyAddress(`${res.data.property.address}, ${res.data.property.city}, ${res.data.property.state} ${res.data.property.zipCode}`);
          setStreetViewImage(`https://maps.googleapis.com/maps/api/streetview?size=380x280
          &location=${res.data.property.address}, ${res.data.property.city}, ${res.data.property.state} ${res.data.property.zipCode}
          &key=AIzaSyBbfrw0oPWsx6_3r3BaHoWhmMMi6e73YXA`);
          setLocation({
            lat: res.data.property.latitude,
            lng: res.data.property.longitude
          });
          setValues({
            beds: res.data.property.bedrooms,
            baths: res.data.property.totalBathrooms,
            yearBuild: res.data.property.effectiveYearBuilt,
            propertyType: res.data.property.propertyType,
            ownerOccupied: res.data.property.ownerOccupied,
            sqft: res.data.property.buildingSqft,
            lotSize: res.data.property.lotSizeSqft,
            estValue: res.data.property.estValue,
            lienAmount: res.data.property.lienAmount,
            remainingBalance: res.data.property.estRemainingBalanceOfOpenLoans,
            owner1FirstName: res.data.property.ownerFirstName1,
            owner1LastName: res.data.property.ownerLastName1,
            owner2FirstName: res.data.property.ownerFirstName2,
            owner2LastName: res.data.property.ownerLastName2,
            companyOwner: res.data.property.ownerFirstName1 === '' ? res.data.property.ownerLastName1 : '',
            differentMailingAddress: res.data.property.mailingAddress === '' ? '' : `${res.data.property.mailingAddress}, ${res.data.property.mailingCity}, ${res.data.property.mailingState} ${res.data.property.mailingZipCode}`
          });
          if (res.data.property.lastSaleAmount > 0) {
            setLastSale(res.data.property.lastSaleAmount);
          }
          if (parseInt(res.data.property.lienAmount, 10) > 0) {
            setLienAmount(parseInt(res.data.property.lienAmount, 10));
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchSaveGoodProperty = async () => {
    const splitterAddress = property.address.split(' ');
    splitterAddress.shift();
    /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
    const propertyData = new FormData();
    propertyData.append('propertyId', property._id);
    propertyData.append('number', property.address.split(' ')[0]);
    propertyData.append('street', splitterAddress.join(' '));
    propertyData.append('unitNumber', property.unitNumber);
    propertyData.append('city', property.city);
    propertyData.append('state', property.state);
    propertyData.append('zipCode', property.zipCode);
    propertyData.append('ownerOccupied', property.ownerOccupied);
    propertyData.append('propertyType', values.propertyType); // put a dropdown in the form
    propertyData.append('bedrooms', values.beds);
    propertyData.append('bathrooms', values.baths);
    propertyData.append('buildingSqft', values.sqft);
    propertyData.append('lotSizeSqft', values.lotSize);
    propertyData.append('yearOfConstruction', values.yearBuild);
    propertyData.append('lastSaleDate', property.lastSaleRecordingDate);
    propertyData.append('mlsStatus', property.mlsStatus);
    propertyData.append('mlsDate', property.mlsDate);
    propertyData.append('mlsPrice', property.mlsAmount === null ? '' : property.mlsAmount);
    propertyData.append('lastSalePrice', lastSale);
    propertyData.append('estValue', values.estValue);
    propertyData.append('remainingBalance', property.estRemainingBalanceOfOpenLoans);
    propertyData.append('lienAmount', lienAmount);
    propertyData.append('estEquity', values.estValue - values.lienAmount - values.remainingBalance);
    propertyData.append('companyOwner', property.ownerFirstName1 === '' ? 'true' : 'false');
    propertyData.append('latitude', property.latitude);
    propertyData.append('longitude', property.longitude);

    await axios.post(`${pageData.apiUrl}/api/save-property`, propertyData)
      .then((res) => {
        if (res.status === 200) {
          ToastsStore.success(res.data.message);
          rest.closeMagic();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Card>
        <CardHeader
          title={propertyAddress.toUpperCase()}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={3}
              sm={4}
              xs={12}
            >
              <img
                src={streetViewImage}
                alt="property front"
                width="100%"
              />
              <div style={{ height: '370px', width: '100%', marginTop: '10px' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyBbfrw0oPWsx6_3r3BaHoWhmMMi6e73YXA' }}
                  center={location}
                  zoom={14}
                >
                  <Pointer
                    lat={location.lat}
                    lng={location.lng}
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
            </Grid>

            <Grid
              className={classes.item}
              item
              md={6}
              sm={4}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                Property Details
              </Typography>
              <Divider style={{ marginBottom: '10px' }} />
              {/* -------------------------------------------------- */}
              <Grid
                container
                spacing={1}
                wrap="wrap"
              >
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Beds"
                    margin="normal"
                    name="beds"
                    onChange={handleChange}
                    type="text"
                    value={values.beds}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Baths"
                    margin="normal"
                    name="baths"
                    onChange={handleChange}
                    type="text"
                    value={values.baths}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Year Built"
                    margin="normal"
                    name="yearBuild"
                    onChange={handleChange}
                    type="text"
                    value={values.yearBuild}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {/* -------------------------------------------------- */}
              <Grid
                container
                spacing={1}
                wrap="wrap"
              >
                <Grid
                  className={classes.item}
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Property Type"
                    margin="normal"
                    name="propertyType"
                    onChange={handleChange}
                    type="text"
                    value={values.propertyType}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Owner Occupied"
                    margin="normal"
                    name="ownerOccupied"
                    onChange={handleChange}
                    type="text"
                    value={values.ownerOccupied}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {/* -------------------------------------------------- */}
              <Grid
                container
                spacing={1}
                wrap="wrap"
              >
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="SQFT"
                    margin="normal"
                    name="sqft"
                    onChange={handleChange}
                    type="text"
                    value={values.sqft}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Lot Size"
                    margin="normal"
                    name="lotSize"
                    onChange={handleChange}
                    type="text"
                    value={values.lotSize}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {/* -------------------------------------------------- */}
              <Grid
                container
                spacing={1}
                wrap="wrap"
              >
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Est Value"
                    margin="normal"
                    name="estValue"
                    onChange={handleChange}
                    type="text"
                    value={values.estValue}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Lien Amount"
                    margin="normal"
                    name="lienAmount"
                    onChange={handleChange}
                    type="text"
                    value={values.lienAmount}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Remaining Balance"
                    margin="normal"
                    name="remainingBalance"
                    onChange={handleChange}
                    type="text"
                    value={values.remainingBalance}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {/* -------------------------------------------------- */}
              <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
              {
                values.owner1FirstName === '' ? (
                  <div>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      Company Owner
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      wrap="wrap"
                    >
                      <Grid
                        className={classes.item}
                        item
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Company Name"
                          margin="normal"
                          name="companyOwner"
                          onChange={handleChange}
                          type="text"
                          value={values.companyOwner}
                          variant="outlined"
                        />
                        {
                          values.differentMailingAddress === '' ? '' : (
                            <TextField
                              fullWidth
                              label="Company Address"
                              margin="normal"
                              name="differentMailingAddress"
                              onChange={handleChange}
                              type="text"
                              value={values.differentMailingAddress}
                              variant="outlined"
                            />
                          )
                        }
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      Owner 1
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      wrap="wrap"
                    >
                      <Grid
                        className={classes.item}
                        item
                        md={6}
                        sm={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="First Name"
                          margin="normal"
                          name="owner1FirstName"
                          onChange={handleChange}
                          type="text"
                          value={values.owner1FirstName}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        className={classes.item}
                        item
                        md={6}
                        sm={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Last Name"
                          margin="normal"
                          name="owner1LastName"
                          onChange={handleChange}
                          type="text"
                          value={values.owner1LastName}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {/* -------------------------------------------------- */}
                    {values.owner2FirstName === '' ? '' : (
                      <div>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Owner 2
                        </Typography>
                        <Grid
                          container
                          spacing={1}
                          wrap="wrap"
                        >
                          <Grid
                            className={classes.item}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="First Name"
                              margin="normal"
                              name="owner2FirstName"
                              onChange={handleChange}
                              type="text"
                              value={values.owner2FirstName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            className={classes.item}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Last Name"
                              margin="normal"
                              name="owner2LastName"
                              onChange={handleChange}
                              type="text"
                              value={values.owner2LastName}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                )
              }
            </Grid>

            <Grid
              className={classes.item}
              item
              md={3}
              sm={4}
              xs={12}
            >
              <PropertyValue property={property} />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            className={classes.buttonSave}
            variant="contained"
            startIcon={<SaveOutlinedIcon />}
            onClick={() => fetchSaveGoodProperty()}
          >
            Save
          </Button>
          <Button
            className={classes.buttonClose}
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={() => rest.closeMagic()}
          >
            CLOSE
          </Button>
        </Box>
      </Card>
    </form>
  );
};

PropertyDetails.propTypes = {
  className: PropTypes.string
};

export default PropertyDetails;
