import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../App';
import Grid from '@mui/material/Grid';

import CardProperty from './CardProperty';

import axios from 'axios';
import pageData from '../../../pageData';

const Tables = ({ ...rest }) => {
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);
  const [properties, setProperties] = useState([]);
  const [state, setState] = useState('FL');
  const [city, setCity] = useState('ALTAMONTE SPRINGS');

  const fetchFindMyFavorites = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);

    await axios.post(`${pageData.apiUrl}/api/find-my-favorites`, userData)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.favorites.map((property) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
            data.push({
              propertyId: property._id,
              number: property.number,
              street: property.street,
              propertyImg: property.propertyImg,
              city: property.city,
              state: property.state,
              zipCode: property.zipCode,
              bedrooms: property.bedrooms,
              bathrooms: property.bathrooms,
              buildingSqft: property.buildingSqft,
              estValue: property.estValue,
              propertyType: property.propertyType,
              status: property.status
            });
            return true;
          });
          setProperties(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchFindMyFavorites();
  }, [state, city]);

  if(rest.state !== state){
    setState(rest.state);
  }

  if(rest.city !== city){
    setCity(rest.city);
  }

  const propertyRemovedFromFavorite = () => {
    fetchFindMyFavorites();
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
      >
        {
          properties.length > 0 ?
          properties.map(property => {
            return <Grid item alignSelf='stretch' >
              <CardProperty 
                property={property} 
                propertyRemoved={() => propertyRemovedFromFavorite()}
              />
            </Grid>
          })
          :
          <h2 style={{marginTop: 50}}>You do not have any property save!</h2>
        }
        
      </Grid>
    </div>
  );
};

export default Tables;
