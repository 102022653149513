import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
// import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
// import ScatterPlotOutlinedIcon from '@material-ui/icons/ScatterPlotOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
// import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
// import TocOutlinedIcon from '@material-ui/icons/TocOutlined';
// import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
// import AlarmOnIcon from '@material-ui/icons/AlarmOn';

import { AppContext } from '../../../App';
import NavItem from './NavItem';

const itemsAdmin = [
  {
    href: '/app/dashboard',
    icon: DashboardOutlinedIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/users',
    icon: PeopleAltOutlinedIcon,
    title: 'Users'
  },
  {
    href: '/app/properties',
    icon: HomeWorkOutlinedIcon,
    title: 'Properties'
  },
  {
    href: '/app/contacts',
    icon: PermContactCalendarOutlinedIcon,
    title: 'Contacts'
  },
  {
    href: '/app/demoaccounts',
    icon: AccountBalanceOutlinedIcon,
    title: 'Demo Accounts'
  },
  {
    href: '/app/settings',
    icon: SettingsOutlinedIcon,
    title: 'Settings'
  },
  {
    href: '/app/account',
    icon: AccountBoxOutlinedIcon,
    title: 'My Profile'
  },
];

const itemsUser = [
  {
    href: '/app/dashboard',
    icon: DashboardOutlinedIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/properties',
    icon: HomeWorkOutlinedIcon,
    title: 'Properties'
  },   
  {
    href: '/app/savedproperties',
    icon: ThumbUpOutlinedIcon,
    title: 'Saved Properties'
  },
  {
    href: '/app/properties',
    icon: ContactMailOutlinedIcon,
    title: 'Contacts'
  }, 
  {
    href: '/app/message',
    icon: SmsOutlinedIcon,
    title: 'Messages'
  },
  {
    href: '/app/calendar',
    icon: EventAvailableOutlinedIcon,
    title: 'Calendar'
  },  
  {
    href: '/app/account',
    icon: AccountBoxOutlinedIcon,
    title: 'My Profile'
  },

  
  // {
  //   href: '/app/settings',
  //   icon: SettingsOutlinedIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/app/orders',
  //   icon: TocOutlinedIcon,
  //   title: 'Open Orders'
  // },
  // {
  //   href: '/app/robots',
  //   icon: FavoriteBorderOutlinedIcon,
  //   title: 'My Robots'
  // },
  // {
  //   href: '/app/follow',
  //   icon: NewReleasesOutlinedIcon,
  //   title: 'Expert Advisors'
  // },
  // {
  //   href: '/app/transactions',
  //   icon: FormatListBulletedIcon,
  //   title: 'Transactions'
  // },
  // {
  //   href: '/app/statistics',
  //   icon: ScatterPlotOutlinedIcon,
  //   title: 'Statistics'
  // },
  // {
  //   href: '/app/projections',
  //   icon: TrendingUpOutlinedIcon,
  //   title: 'Projections'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 80,
    height: 80,
    marginBottom: 20
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useContext(AppContext);
  const userData = JSON.parse(user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        {
          userData.userType === 'Admin'
            ? (
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                src="/static/images/avatars/admin.png"
                to="/app/account"
              />
            )
            : (
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                src={userData.profilePic}
                to="/app/account"
              />
            )
        }

        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {userData.name}
        </Typography>
        {/* <Typography
          color="textSecondary"
          variant="body2"
        >
          {userData.userType}
        </Typography> */}
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {
            userData.userType === 'Admin'
              ? (
                itemsAdmin.map((item) => (
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
                ))
              )
              : (
                itemsUser.map((item) => (
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
                ))
              )
          }
        </List>
      </Box>

      <Box flexGrow={1} />
      <Divider />
      <Box
        p={2}
        m={2}
      >
        <NavItem
          href="/"
          title="Logout"
          icon={ExitToAppOutlinedIcon}
          onClick={() => {
            setUser('');
            localStorage.clear();
            navigate('/', { replace: true });
          }}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
