import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import Tables from './Tables';
import PropertyDetails from './PropertyDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));

const StatisticListView = () => {
  const classes = useStyles();
  const [property, serProperty] = useState('');

  const handleSelectProperty = (propertySelected) => {
    serProperty(propertySelected);
  };

  const closeSelected = () => {
    serProperty('');
  };

  return (
    <Page
      className={classes.root}
      title="Find It Easy - All Properties"
    >
      <Container maxWidth={false}>
        {property === '' ? (
          <Box>
            <Tables selectProperty={(propertySelected) => handleSelectProperty(propertySelected)} />
          </Box>
        )
          : (
            <Box>
              <PropertyDetails property={property} closeMagic={() => closeSelected()} />
            </Box>
          )}
      </Container>
    </Page>
  );
};

export default StatisticListView;
