import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../App';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';
import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropertyDetails from './PropertyDetails';


import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

import axios from 'axios';
import pageData from '../../../pageData';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CardProperty = ({ className, ...rest }) => {
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);
  const [contacts, setContacts] = useState([]);

  // console.log(contacts)
  // console.log(rest)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContact = async () => {

    const propertyData = new FormData();
    propertyData.append('number', rest.property.number);
    propertyData.append('street', rest.property.street);
    propertyData.append('city', rest.property.city);
    propertyData.append('zipCode', rest.property.zipCode);

    await axios.post(`${pageData.apiUrl}/api/find-contact-by-address`, propertyData)
      .then((res) => {
        if (res.status === 200) {
          handleOpen();
          if (res.data.contacts.length > 0) {
            let contactList = [];
            res.data.contacts.map(item => {
              contactList.push({ 
                contactId: item._id, 
                firstName: item.firstName, 
                lastName: item.lastName, 
                phoneNumber: item.phoneNumber,
                userStatus: item.userStatus
              })
            });
            setContacts(contactList);
          }
          // console.log(res.data.contacts)



        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  };

  const handleSaveFavorite = async () => {

    const propertyData = new FormData();
    propertyData.append('userId', authUser.userId);
    propertyData.append('propertyId', rest.property.propertyId);

    await axios.post(`${pageData.apiUrl}/api/save-favorite`, propertyData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data === 1) {
            rest.propertySaved();
            ToastsStore.success("Property saved to favorite!");
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSendSms = async (contact) => {

    const messageData = new FormData();
    messageData.append('userId', authUser.userId);
    messageData.append('myName', authUser.name.split(' ')[0]);
    messageData.append('localPhoneNumber', rest.localPhoneNumber);
    messageData.append('contactId', contact.contactId);
    messageData.append('propertyId', rest.property.propertyId);
    messageData.append('contactName', contact.firstName);
    messageData.append('contactPhoneNumber', contact.phoneNumber);
    messageData.append('propertyNumber', rest.property.number);
    messageData.append('propertyStreet', rest.property.street);
    messageData.append('propertyCity', rest.property.city);
    messageData.append('propertyZipCode', rest.property.zipCode);

    // console.log(contact)

    await axios.post(`${pageData.apiUrl}/api/first-sms-to-contact`, messageData)
      .then((res) => {

        if (res.status === 200) {
          console.log(res.data)
          handleContact();
        //   if (res.data === 1) {
        //     rest.propertySaved();
        //     ToastsStore.success("Property saved to favorite!");
        //   }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return (
    <div>

      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />

      <Card style={{ marginBottom: 5, width: 370 }} key={rest.property.propertyId}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={handleSaveFavorite}
            >
              <StarOutlineIcon />
            </IconButton>
          }

          title={`${rest.property.number} ${rest.property.street}`}
          subheader={`${rest.property.city}, ${rest.property.state} ${rest.property.zipCode}`}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
          style={{ height: 65 }}
        />

        <div
          style={{
            position: 'relative',
            textAlign: 'center'
          }}
        >

          {
            rest.property.status && rest.property.status[0] === 'OFF MARKET' ?
              <div
                style={{
                  float: 'left',
                  position: 'absolute',
                  marginLeft: 15,
                  marginTop: 15,
                  background: '#61F74C',
                  textAlign: 'center',
                  padding: 3,
                  borderRadius: 4,
                  boxShadow: '1px 1px 2px black'
                }}
              >
                <Typography variant="body2" color="text.primary">
                  <strong>
                    {rest.property.status && rest.property.status[0]}
                  </strong>
                </Typography>
              </div>
              :
              <div
                style={{
                  float: 'left',
                  position: 'absolute',
                  marginLeft: 15,
                  marginTop: 15,
                  background: '#FFFF00',
                  textAlign: 'center',
                  padding: 3,
                  borderRadius: 4,
                  boxShadow: '1px 1px 2px black'
                }}
              >
                <Typography variant="body2" color="text.primary">
                  <strong>
                    {rest.property.status && rest.property.status[0]}
                  </strong>
                </Typography>
              </div>
          }

          <CardMedia
            component="img"
            height="194"
            image={rest.property.propertyImg}
            alt="good house picture"
          />

        </div>

        <CardContent>
          <div style={{ marginLeft: 15, marginBottom: 10 }}>
            <Typography variant="body2" color="text.primary">
              <h3>{`Est. Value: $${rest.property.estValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h3>
            </Typography>
          </div>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4} container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" color="text.secondary">
                <Grid item container
                  direction="row"
                >
                  <Grid item xs={6}>
                    <BedOutlinedIcon />
                  </Grid>
                  <Grid item xs={6}>
                    <h3>{`${rest.property.bedrooms}Beds`}</h3>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>

            <Grid item xs={3} container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" color="text.secondary">
                <Grid item container
                  direction="row"
                >
                  <Grid item xs={6}>
                    <BathtubOutlinedIcon />
                  </Grid>
                  <Grid item xs={6}>
                    <h3>{`${rest.property.bathrooms}Baths`}</h3>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>

            {rest.property.buildingSqft === 0 ? '' :
              <Grid item xs={5} container
                justifyContent="center"
                direction="row"
              >
                <Typography variant="body2" color="text.secondary">
                  <Grid item container
                    direction="row"
                  >
                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                      <h3 style={{ marginLeft: -20 }}>{rest.property.buildingSqft}</h3>
                    </Grid>
                    <Grid item xs={6}>
                      <h3>sqft</h3>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            }

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                <h3>{rest.property.propertyType}</h3>
              </Typography>
            </Grid>

          </Grid>
        </CardContent>

        <CardActions disableSpacing>
          <Grid container columnSpacing={{ xs: 2 }} style={{ textAlign: 'center', marginBottom: 10 }}>
            <Grid item xs={6}>
              <Button variant="outlined" color="secondary" endIcon={<DeleteIcon />}>
                Ignore
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                endIcon={<SendIcon />}
                onClick={handleContact}
              >
                Contact
              </Button>
            </Grid>
          </Grid>

        </CardActions>
      </Card>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{`${rest.property.number} ${rest.property.street}, ${rest.property.city}, ${rest.property.state} ${rest.property.zipCode}`}</DialogTitle>
        <DialogContent>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

            <Grid item xs={12} sm={12} lg={7} item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <PropertyDetails propertyId={rest.property.propertyId} />
            </Grid>

            <Grid item xs={12} sm={12} lg={5} item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >

              <Grid
                container
                xs={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Typography variant="body1" color="text.primary">
                  <h4>{`My Local Number: (${rest.localPhoneNumber.slice(2, 5)}) ${rest.localPhoneNumber.slice(5, 8)}-${rest.localPhoneNumber.slice(8, 12)}`}</h4>
                </Typography>
              </Grid>

              {/* <h1>Text going here...</h1> */}

              <Divider />

              {
                contacts && contacts.map((contact) => {
                  console.log(contact);
                  if (typeof contact.phoneNumber !== 'undefined') {
                    return (
                      <div>
                        <Grid container style={{ marginTop: 10, marginBottom: 10 }}>

                          <Grid item xs={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginTop: 10 }}
                          >
                            <PhoneOutlinedIcon />
                          </Grid>

                          <Grid item xs={7}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="body1" color="text.primary">
                              <h4>{`${contact.firstName} ${contact.lastName}`}</h4>
                              <h4>{`(${contact.phoneNumber.slice(0, 3)}) ${contact.phoneNumber.slice(3, 6)}-${contact.phoneNumber.slice(6, 10)}`}</h4>
                            </Typography>
                          </Grid>

                          <Grid item xs={4}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginTop: 6 }}
                          >
                            {
                              contact.userStatus.filter(({ userId }) => userId === authUser.userId).length > 0 ?
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  startIcon={<RemoveRedEyeOutlinedIcon />}
                                  // onClick={() => handleSendSms(contact)}
                                >
                                  View
                                </Button>
                                :
                                <Button
                                  variant="outlined"
                                  startIcon={<SendOutlinedIcon />}
                                  onClick={() => handleSendSms(contact)}
                                >
                                  Send
                                </Button>
                            }
                          </Grid>

                        </Grid>

                        <Divider />
                      </div>
                    )
                  }
                })
              }

            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

CardProperty.propTypes = {
  className: PropTypes.string
};

export default CardProperty;
