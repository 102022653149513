import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

import axios from 'axios';
import pageData from '../../../pageData';

const columns = [
  {
    name: 'contactId',
    options: {
      display: false,
    }
  },
  {
    name: 'fullName',
    label: 'Full Name',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'number',
    label: 'Number',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'street',
    label: 'Street',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'unitNumber',
    label: 'Apt',
    options: {
      filter: true,
      sort: true,
    }
  }
];

const Tables = ({ ...rest }) => {
  const [contacts, setContacts] = useState([]);

  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none',
    onRowClick: (rowData) => rest.selectContact(rowData[0])
  };

  const fetchExperts = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-some-contacts`)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.contacts.map((contact) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
            data.push({
              contactId: contact._id,
              fullName: `${contact.firstName} ${contact.lastName}`,
              number: contact.number,
              street: contact.street,
              unitNumber: contact.unitNumber
            });
            return true;
          });
          setContacts(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  return (
    <MUIDataTable
      title="All Contacts"
      data={contacts}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
