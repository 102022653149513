import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';

import axios from 'axios';
import pageData from '../../pageData';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 380
  },
  imageMobile: {
    marginTop: 30,
    display: 'inline-block',
    maxWidth: '100%',
    width: 250
  }
}));

const ActiveAccount = () => {
  const [width] = useState(window.innerWidth);
  const [message, setMessage] = useState('');

  const fetchActivate = async () => {
    const activekey = window.location.pathname.split('/')[2];
    await axios.get(`${pageData.apiUrl}/userService/activate/${activekey}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.status)
          if (res.data.success) {
            setMessage('Congratulations. Your account is now activated.');
          } else {
            setMessage('This link was already used or is invalid.');
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchActivate();
  }, []);

  return (
    <div style={{
      backgroundImage: `url("/static/images/close.jpg")`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100%'
    }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container>
          <Box mb={2} textAlign="center">
            <Typography
              color="textPrimary"
              variant={width > 500 ? 'h1' : 'h4'}
            >
              {message}
            </Typography>
          </Box>
          <Box mb={2} textAlign="center">
            <Typography
              color="textPrimary"
              variant="h4"
            >
              <Link
                component={RouterLink}
                to="/login"
                variant="h4"
              >
                Sign in now!
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ActiveAccount;
