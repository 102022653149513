import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
// import './mixins/chartjs';
import theme from './theme';

import routesAuth from './routes/routesAuth';
import routesAdmin from './routes/routesAdmin';
import routesUser from './routes/routesUser';

import useLocalState from './utils/hooks';
import setAuthToken from './utils/setAuthToken';
import jwtDecode from 'jwt-decode';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwtDecode(token);
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // // Logout user
    // store.dispatch(logoutUser());
    // // Redirect to login
    // window.location.href = './login';
    console.log('On APP component I need to force to logoff user, token expire already.');
  }
}

export const AppContext = React.createContext();

const App = () => {
  const routingAuth = useRoutes(routesAuth);
  const routingAdmin = useRoutes(routesAdmin);
  const routingUser = useRoutes(routesUser);

  const [token, setToken] = useLocalState('authUser');
  let userTypeView = '';
  if (token) {
    userTypeView = JSON.parse(token).userType === 'User' ? routingUser : routingAdmin;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppContext.Provider value={[token, setToken]}>
        {token ? userTypeView : routingAuth}
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
