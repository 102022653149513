import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Chat from './Chat';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SmsListView = () => {
  const classes = useStyles();
  const [sms, setSms] = useState('');

  const handleSelectSms = (smsSelected) => {
    setSms(smsSelected);
  };

  return (
    <Page
      className={classes.root}
      title="Find It Easy - Messages"
    >
      <Container maxWidth={false}>
        {/* <Toolbar /> */}

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Results
              selectSms={(smsSelected) => handleSelectSms(smsSelected)}
            />
          </Grid>
          <Grid item xs={4}>
            {
              sms !== '' &&
              <Item style={{ height: window.innerHeight - 115 }}>
                <Chat smsId={sms} />
              </Item>
            }
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SmsListView;
