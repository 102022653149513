import React, { useState, useRef, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

import axios from 'axios';
import pageData from '../../../pageData';
import { AppContext } from '../../../App';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();

  const [userData, setUserData] = useContext(AppContext);
  const authUser = JSON.parse(userData);
  
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangePhone = (event) => {
    let tempPhone = event.target.value;    
    setPhone(`${tempPhone.substring(0, 3)} ${tempPhone.substring(3, 7)} ${tempPhone.substring(7, tempPhone.length)}`.replace(/  /gm, ' ').trim());
  };

  const handleGetUserInfo = async () => {
    const userInfo = new FormData();
    userInfo.append('userId', authUser.userId);

    await axios.post(`${pageData.apiUrl}/api/get-user-info`, userInfo)
      .then((res) => {     
        if (res.status === 200) {
          let phoneNumber = res.data.user.phone && res.data.user.phone.toString()
          setName(res.data.user.name);
          setPhone(res.data.user.phone ? `${phoneNumber.substring(0, 3)} ${phoneNumber.substring(3, 6)} ${phoneNumber.substring(6, phoneNumber.length)}` : '');
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  const updateUserInfo = () => {
    setUserData(JSON.stringify({
      ...authUser,
      name
    }));
  }

  const handleUpdateUser = async () => {
    const userInfo = new FormData();
    userInfo.append('userId', authUser.userId);
    userInfo.append('name', name);
    userInfo.append('phone', phone);

    await axios.post(`${pageData.apiUrl}/api/update-user-info`, userInfo)
      .then((res) => {     
        if (res.status === 200) {
          ToastsStore.success(res.data.message);
          setTimeout(() => {
            updateUserInfo();
          }, 200)  
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify your full name"
                label="Full name"
                name="name"
                onChange={handleChangeName}
                required
                value={name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChangePhone}
                required
                value={phone}
                variant="outlined"
                inputProps={{ maxLength: 12 }}
              />
            </Grid>

            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Select State"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}


          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdateUser} 
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
