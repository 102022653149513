import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';

import { AppContext } from '../../../App';
import axios from 'axios';
import pageData from '../../../pageData';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const PhoneNumber = ({ className, ...rest }) => {
  const classes = useStyles();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);
  const [phoneNumber, setPhoneNumber] = useState('#############');

  const fetchMyTwilioPhoneNumbers = async () => {
    rest.refreshPhoneNumber();
    await axios.get(`${pageData.apiUrl}/api/get-twilio-phone/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.twilioPhone.twilioPhone[0])
          setPhoneNumber(res.data.twilioPhone.twilioPhone[0].phone)
          rest.refreshPhoneNumber()
          // if (res.data.empty) {
          //   setLastOpen('Nothing Open!');
          // } else {
          //   setOrderCount(res.data.openOrders);
          //   setLastOpen(res.data.lastOpen);
          //   setTemporality(res.data.temporality);
          // }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  if(rest.data){
    fetchMyTwilioPhoneNumbers();  
  }

  useEffect(() => {
    fetchMyTwilioPhoneNumbers();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              LOCAL PHONE NUMBER
            </Typography>
            <Box ml={3} mt={3}>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {`(${phoneNumber.substring(2, 5)}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8, 12)}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PermPhoneMsgOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        
      </CardContent>
    </Card>
  );
};

PhoneNumber.propTypes = {
  className: PropTypes.string
};

export default PhoneNumber;
