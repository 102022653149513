import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../App';
import Grid from '@mui/material/Grid';

import CardProperty from './CardProperty';

import axios from 'axios';
import pageData from '../../../pageData';

const Tables = ({ ...rest }) => {
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);
  const [properties, setProperties] = useState([]);
  const [state, setState] = useState('FL');
  const [city, setCity] = useState('ALTAMONTE SPRINGS');
  const [localPhoneNumber, setLocalPhoneNumber] = useState('');

  const fetchProperties = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('state', state);
    userData.append('city', city);

    await axios.post(`${pageData.apiUrl}/api/get-pay-as-go-properties`, userData)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.properties.map((property) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
            data.push({
              propertyId: property._id,
              number: property.number,
              street: property.street,
              propertyImg: property.propertyImg,
              city: property.city,
              state: property.state,
              zipCode: property.zipCode,
              bedrooms: property.bedrooms,
              bathrooms: property.bathrooms,
              buildingSqft: property.buildingSqft,
              estValue: property.estValue,
              propertyType: property.propertyType,
              status: property.status
            });
            return true;
          });
          setProperties(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchProperties();
  }, [state, city]);

  if(rest.state !== state){
    setState(rest.state);
  }

  if(rest.city !== city){
    setCity(rest.city);
  }

  const propertySavedToFavorite = () => {
    fetchProperties();
  };

  const fetchMyTwilioPhoneNumbers = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-twilio-phone/${authUser.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setLocalPhoneNumber(res.data.twilioPhone.twilioPhone[0]);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchMyTwilioPhoneNumbers();
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={2}
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
      >
        {properties.map(property => {
          return <Grid item alignSelf='stretch' >
            <CardProperty 
              localPhoneNumber={localPhoneNumber.phone}
              property={property} 
              propertySaved={() => propertySavedToFavorite()}
            />
          </Grid>
        })}
      </Grid>
    </div>
  );
};

export default Tables;
