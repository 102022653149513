import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../App';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';
import SaveIcon from '@mui/icons-material/Save';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import StarRateSharpIcon from '@mui/icons-material/StarRateSharp';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

import axios from 'axios';
import pageData from '../../../pageData';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CardProperty = ({ className, ...rest }) => {
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);

  const handleContact = async () => {

    const propertyData = new FormData();
    propertyData.append('number', rest.property.number);
    propertyData.append('street', rest.property.street);
    propertyData.append('city', rest.property.city);
    propertyData.append('zipCode', rest.property.zipCode);

    await axios.post(`${pageData.apiUrl}/api/find-contact-by-address`, propertyData)
      .then((res) => {
        if (res.status === 200) {
          //   setProperty(res.data.property);
          //   setPropertyAddress(`${res.data.property.address}, ${res.data.property.city}, ${res.data.property.state} ${res.data.property.zipCode}`);
          //   setStreetViewImage(`https://maps.googleapis.com/maps/api/streetview?size=380x280
          //   &location=${res.data.property.address}, ${res.data.property.city}, ${res.data.property.state} ${res.data.property.zipCode}
          //   &key=AIzaSyBbfrw0oPWsx6_3r3BaHoWhmMMi6e73YXA`);
          //   setLocation({
          //     lat: res.data.property.latitude,
          //     lng: res.data.property.longitude
          //   });
          //   setValues({
          //     beds: res.data.property.bedrooms,
          //     baths: res.data.property.totalBathrooms,
          //     yearBuild: res.data.property.effectiveYearBuilt,
          //     propertyType: res.data.property.propertyType,
          //     ownerOccupied: res.data.property.ownerOccupied,
          //     sqft: res.data.property.buildingSqft,
          //     lotSize: res.data.property.lotSizeSqft,
          //     estValue: res.data.property.estValue,
          //     lienAmount: res.data.property.lienAmount,
          //     remainingBalance: res.data.property.estRemainingBalanceOfOpenLoans,
          //     owner1FirstName: res.data.property.ownerFirstName1,
          //     owner1LastName: res.data.property.ownerLastName1,
          //     owner2FirstName: res.data.property.ownerFirstName2,
          //   });
          //   if (res.data.property.lastSaleAmount > 0) {
          //     setLastSale(res.data.property.lastSaleAmount);
          //   }
          //   if (parseInt(res.data.property.lienAmount, 10) > 0) {
          //     setLienAmount(parseInt(res.data.property.lienAmount, 10));
          //   }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  };


  const handleSaveFavorite = async () => {
    const propertyData = new FormData();
    propertyData.append('userId', authUser.userId);
    propertyData.append('propertyId', rest.property.propertyId);

    await axios.post(`${pageData.apiUrl}/api/remove-favorite`, propertyData)
      .then((res) => {
        if (res.status === 200) {
          rest.propertyRemoved();
          ToastsStore.success("Property removed from favorite!");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps

  };

  return (
    <div>

      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />

      <Card style={{ marginBottom: 5, width: 370 }} key={rest.property.propertyId}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={handleSaveFavorite}
            >
              <StarRateSharpIcon />
            </IconButton>
          }

          title={`${rest.property.number} ${rest.property.street}`}
          subheader={`${rest.property.city}, ${rest.property.state} ${rest.property.zipCode}`}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
          style={{ height: 65 }}
        />

        <div
          style={{
            position: 'relative',
            textAlign: 'center'
          }}
        >

          {
            rest.property.status && rest.property.status[0] === 'OFF MARKET' ?
              <div
                style={{
                  float: 'left',
                  position: 'absolute',
                  marginLeft: 15,
                  marginTop: 15,
                  background: '#61F74C',
                  textAlign: 'center',
                  padding: 3,
                  borderRadius: 4,
                  boxShadow: '1px 1px 2px black'
                }}
              >
                <Typography variant="body2" color="text.primary">
                  <strong>
                    {rest.property.status && rest.property.status[0]}
                  </strong>
                </Typography>
              </div>
              :
              <div
                style={{
                  float: 'left',
                  position: 'absolute',
                  marginLeft: 15,
                  marginTop: 15,
                  background: '#FFFF00',
                  textAlign: 'center',
                  padding: 3,
                  borderRadius: 4,
                  boxShadow: '1px 1px 2px black'
                }}
              >
                <Typography variant="body2" color="text.primary">
                  <strong>
                    {rest.property.status && rest.property.status[0]}
                  </strong>
                </Typography>
              </div>
          }

          <CardMedia
            component="img"
            height="194"
            image={rest.property.propertyImg}
            alt="good house picture"
          />

        </div>

        <CardContent>
          <div style={{ marginLeft: 15, marginBottom: 10 }}>
            <Typography variant="body2" color="text.primary">
              <h3>{`Est. Value: $${rest.property.estValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h3>
            </Typography>
          </div>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4} container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" color="text.secondary">
                <Grid item container
                  direction="row"
                >
                  <Grid item xs={6}>
                    <BedOutlinedIcon />
                  </Grid>
                  <Grid item xs={6}>
                    <h3>{`${rest.property.bedrooms}Beds`}</h3>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>

            <Grid item xs={3} container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2" color="text.secondary">
                <Grid item container
                  direction="row"
                >
                  <Grid item xs={6}>
                    <BathtubOutlinedIcon />
                  </Grid>
                  <Grid item xs={6}>
                    <h3>{`${rest.property.bathrooms}Baths`}</h3>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>

            {rest.property.buildingSqft === 0 ? '' :
              <Grid item xs={5} container
                justifyContent="center"
                direction="row"
              >
                <Typography variant="body2" color="text.secondary">
                  <Grid item container
                    direction="row"
                  >
                    <Grid item xs={6} style={{ textAlign: 'left' }}>
                      <h3 style={{ marginLeft: -20 }}>{rest.property.buildingSqft}</h3>
                    </Grid>
                    <Grid item xs={6}>
                      <h3>sqft</h3>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            }

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                <h3>{rest.property.propertyType}</h3>
              </Typography>
            </Grid>

          </Grid>
        </CardContent>

        <CardActions disableSpacing>
          <Grid container columnSpacing={{ xs: 2 }} style={{ textAlign: 'center', marginBottom: 10 }}>
            <Grid item xs={6}>
              <Button variant="outlined" color="secondary" endIcon={<DeleteIcon />}>
                Ignore
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                endIcon={<SendIcon />}
                onClick={handleContact}
              >
                Contact
              </Button>
            </Grid>
          </Grid>

        </CardActions>
      </Card>
    </div>
  );
}



CardProperty.propTypes = {
  className: PropTypes.string
};

export default CardProperty;
