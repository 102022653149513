import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  TextField,
  makeStyles
} from '@material-ui/core';
import allCities from '../../../cities';

const useStyles = makeStyles((theme) => ({
  root: {},
  stateInput: {
    marginRight: theme.spacing(2),
    width: 130,
    marginTop: 12,
    marginBottom: 12
  },
  cityInput: {
    width: 250,
    marginTop: 12,
    marginBottom: 12
  }
}));

const states = [
  {
    value: 'AZ',
    label: 'ARIZONA'
  },
  {
    value: 'FL',
    label: 'FLORIDA'
  },
  {
    value: 'OH',
    label: 'OHIO'
  },
  {
    value: 'TX',
    label: 'TEXAS'
  }
];

const MainToolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const [usstate, setUsstate] = useState('FL');
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');

  const fetchCities = async () => {
    handleUpdateCities(usstate);
    rest.searchbycity(allCities.FL[0].city);
  };

  useEffect(() => {
    fetchCities();
  }, []);


  const handleChangeState = (event) => {
    setUsstate(''); 
    rest.searchbystate(event.target.value);
    setUsstate(event.target.value);    
    handleUpdateCities(event.target.value);

    if(event.target.value === 'AZ'){ return rest.searchbycity(allCities.AZ[0].city) };
    if(event.target.value === 'FL'){ return rest.searchbycity(allCities.FL[0].city) };
    if(event.target.value === 'OH'){ return rest.searchbycity(allCities.OH[0].city) };
    if(event.target.value === 'TX'){ return rest.searchbycity(allCities.TX[0].city) };    
  };

  const handleUpdateCities = (value) => {
    let currentCities = []
    if (value === 'AZ') {
      setCity(allCities.AZ[0].city);
      allCities.AZ.map(item => {
        return currentCities.push({
          value: item.city,
          // label: `${item.city}  (${item.count})`
          label: `${item.city}`
        })
      });
    }
    if (value === 'FL') {
      setCity(allCities.FL[0].city);
      allCities.FL.map(item => {
        return currentCities.push({
          value: item.city,
          // label: `${item.city}  (${item.count})`
          label: `${item.city}`
        })
      });
    }
    if (value === 'OH') {
      setCity(allCities.OH[0].city);
      allCities.OH.map(item => {
        return currentCities.push({
          value: item.city,
          label: `${item.city}  (${item.count})`
        })
      });
    }
    if (value === 'TX') {
      setCity(allCities.TX[0].city);
      allCities.TX.map(item => {
        return currentCities.push({
          value: item.city,
          label: `${item.city}  (${item.count})`
        })
      });
    }

    setCities(currentCities);

  };

  const handleChangeCity = (event) => {
    rest.searchbycity(event.target.value);
    setCity(event.target.value);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        mb={3}
        syle={{ height: 300 }}
      >
        <Card >
          <Box
            display="flex"
            justifyContent="center"
          >

            <TextField
              label="State"
              name="state"
              onChange={handleChangeState}
              required
              select
              SelectProps={{ native: true }}
              value={usstate}
              variant="outlined"
              className={classes.stateInput}
            >
              {states.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>

            {
              cities &&
              <TextField
                label="City"
                name="city"
                onChange={handleChangeCity}
                required
                select
                SelectProps={{ native: true }}
                value={city}
                variant="outlined"
                className={classes.cityInput}
              >
                {cities.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            }

          </Box>
        </Card>
      </Box>
    </div>
  );
};

MainToolbar.propTypes = {
  className: PropTypes.string
};

export default MainToolbar;
