import React, { useState, useContext } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';

import { AppContext } from '../../../App';
import Page from '../../../components/Page';
import AccountBalance from './AccountBalance';
import Properties from './Properties';
import PhoneNumber from './PhoneNumber';
import CompleteRegistration from './CompleteRegistration';

// import AccountInfo from './AccountInfo';
// import ScannerId from './ScannerId';
// import LatestTransactions from './LatestTransactions';
// import FollowByTemporality from './FollowByTemporality';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [width] = useState(window.innerWidth);
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);

  const [accountRefresh, setAccountRefresh] = useState(true);
  const [twilioNumberRefresh, setTwilioNumberRefresh] = useState(true);

  const handleAccountRefresh = () => {
    setAccountRefresh(!accountRefresh);
  }

  const handleTwilioNumberRefresh = () => {
    setTwilioNumberRefresh(!twilioNumberRefresh);
  }

  const handleFisnishRegistration = () => {
    setAccountRefresh(!accountRefresh);
    setTwilioNumberRefresh(!twilioNumberRefresh);
  }
 
  return (
    <Page
      className={classes.root}
      title="Find It Easy - Dashboard"
    >

      {width > 500
        ? (
          <Container maxWidth={false}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                <AccountBalance
                  data={accountRefresh}
                  refreshAccount={() => handleAccountRefresh()}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                {/* <AccountBalance /> */}
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                <PhoneNumber 
                  data={twilioNumberRefresh}
                  refreshPhoneNumber={() => handleTwilioNumberRefresh()}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                xs={12}
              >
                <Properties height={560} />
              </Grid>

              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={12}
                container
              >
                <Grid
                  item
                  lg={12}
                  style={{ marginBottom: 14 }}
                >
                  {/* <AccountBalance /> */}
                </Grid>
                <Grid
                  item
                  lg={12}
                >
                  {/* <AccountBalance /> */}
                </Grid>
              </Grid>

              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <AccountInfo />
              </Grid> */}
              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <ScannerId />
              </Grid> */}
              {/* <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <LatestTransactions />
              </Grid> */}
              {/* <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <FollowByTemporality />
              </Grid> */}
            </Grid>
          </Container>
        )
        : (
          <Container maxWidth={false}>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xl={3}
                xs={12}
              >
                <Properties height={250} />
              </Grid>
              {/*
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <OpenOrders />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <LatestTransactions />
              </Grid> */}
            </Grid>
          </Container>
        )}

      {/* --------------------------------------   COMPLETE REGISTRATION    ------------------------------------------ */}
      {/* --------    (Validate Personal Phone Number, Pay Subscription, Select TWILIO local phone number)   --------- */}
      <CompleteRegistration 
        finishRegistration={() => handleFisnishRegistration()}
      />

    </Page>
  );
};

export default Dashboard;
