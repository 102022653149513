import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import axios from 'axios';
import pageData from '../../../pageData';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  },
  positiveValue: {
    color: colors.green[900]
  }
}));

const LowerAccount = ({ className, ...rest }) => {
  const classes = useStyles();
  const [balance, setBalance] = useState(0);
  const [platform, setPlatform] = useState('');
  const [modify, setModify] = useState('');
  const [loading] = useState(0);

  const fetchProfitLatest = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-lower-account`)
      .then((res) => {
        if (res.status === 200) {
          setBalance(Math.round(res.data.account.freeMargin));
          setPlatform(res.data.account.description);
          setModify(res.data.account.lastModify.split('T')[0]);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchProfitLatest();
  }, [loading]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              LOWER ACCOUNT
            </Typography>
            <Box ml={2}>
              <Typography
                className={classes.positiveValue}
                variant="h3"
              >
                $
                {balance}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          ml={2}
        >
          <Grid container>
            <Grid item xs={5}>
              <Typography
                variant="h5"
              >
                Platform:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
              >
                {platform}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Typography
                variant="h5"
              >
                Last Modify:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
              >
                {modify}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

LowerAccount.propTypes = {
  className: PropTypes.string
};

export default LowerAccount;
