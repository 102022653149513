import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import GoogleMapReact from 'google-map-react';

import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';

import axios from 'axios';
import pageData from '../../../pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

import Pointer from './Pointer';
import PropertyValue from './PropertyValue';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonClose: {
    color: '#b71c1c',
    background: '#fce4ec',
    marginLeft: theme.spacing(1)
  },
  buttonSave: {
    color: '#0d47a1',
    background: '#e3f2fd',
    marginLeft: theme.spacing(1)
  },
}));

const PropertyDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [property, setProperty] = useState({});
  const [streetViewImage, setStreetViewImage] = useState('');
  const [location, setLocation] = useState({});

  const [values, setValues] = useState({
    beds: '',
    baths: '',
    yearBuild: '',
    propertyType: '',
    ownerOccupied: '',
    sqft: '',
    lotSize: '',
    estValue: '',
    lienAmount: '',
    remainingBalance: '',
    owner1FirstName: '',
    owner1LastName: '',
    owner2FirstName: '',
    owner2LastName: '',
    companyOwner: '',
    differentMailingAddress: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const fetchProperties = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-good-property-by-id/${rest.propertyId}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.property)
          setProperty(res.data.property);
          // setPropertyAddress(`${res.data.property.number} ${res.data.property.street}, ${res.data.property.city}, ${res.data.property.state} ${res.data.property.zipCode}`);
          setStreetViewImage(`https://maps.googleapis.com/maps/api/streetview?size=380x280
          &location=${res.data.property.number} ${res.data.property.street}, ${res.data.property.city}, ${res.data.property.state} ${res.data.property.zipCode}
          &key=AIzaSyBbfrw0oPWsx6_3r3BaHoWhmMMi6e73YXA`);
          setLocation({
            lat: res.data.property.latitude,
            lng: res.data.property.longitude
          });
          setValues({
            beds: res.data.property.bedrooms,
            baths: res.data.property.totalBathrooms,
            yearBuild: res.data.property.effectiveYearBuilt,
            propertyType: res.data.property.propertyType,
            ownerOccupied: res.data.property.ownerOccupied,
            sqft: res.data.property.buildingSqft,
            lotSize: res.data.property.lotSizeSqft,
            estValue: res.data.property.estValue,
            lienAmount: res.data.property.lienAmount,
            remainingBalance: res.data.property.estRemainingBalanceOfOpenLoans,
            owner1FirstName: res.data.property.ownerFirstName1,
            owner1LastName: res.data.property.ownerLastName1,
            owner2FirstName: res.data.property.ownerFirstName2,
            owner2LastName: res.data.property.ownerLastName2,
            companyOwner: res.data.property.ownerFirstName1 === '' ? res.data.property.ownerLastName1 : '',
            differentMailingAddress: res.data.property.mailingAddress === '' ? '' : `${res.data.property.mailingAddress}, ${res.data.property.mailingCity}, ${res.data.property.mailingState} ${res.data.property.mailingZipCode}`
          });
          if (res.data.property.lastSaleAmount > 0) {
            // setLastSale(res.data.property.lastSaleAmount);
          }
          if (parseInt(res.data.property.lienAmount, 10) > 0) {
            // setLienAmount(parseInt(res.data.property.lienAmount, 10));
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />

      <Grid container spacing={2} >
        <Grid item xs={6}>
          <img
            src={streetViewImage}
            alt="property front"
            width="100%"
          />
        </Grid>
        <Grid item xs={6}>
          <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyBbfrw0oPWsx6_3r3BaHoWhmMMi6e73YXA' }}
              center={location}
              zoom={17}
            >
              <Pointer
                lat={location.lat}
                lng={location.lng}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        style={{ marginTop: 15 }}
      >

        {property.bedrooms !== 0 &&
          <Grid
            xs={4}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Typography variant="body2" color="text.secondary">
              <Grid
                container
                direction="row"
              >
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={2}>
                  <BedOutlinedIcon />
                </Grid>
                <Grid item xs={5}>
                  <h3>{`${property.bedrooms} Beds`}</h3>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        }

        {property.bathrooms !== 0 &&
          <Grid
            xs={4}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Typography variant="body2" color="text.secondary">
              <Grid
                container
                direction="row"
              >
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={2}>
                  <BathtubOutlinedIcon />
                </Grid>
                <Grid item xs={5}>
                  <h3>{`${property.bathrooms} Baths`}</h3>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        }

        {property.buildingSqft !== 0 &&
          <Grid
            xs={4}
            item
            style={{textAlign: 'center'}}
          >
            <Typography variant="body2" color="text.secondary">
              <h3 style={{ marginLeft: -20 }}>{`${property.buildingSqft} sqft`}</h3>
            </Typography>
          </Grid>
        }

        {property.propertyType &&
          <Grid
            xs={4}
            item
            style={{textAlign: 'center'}}
          >
            <Typography variant="body2" color="text.secondary">
              <h3>{property.propertyType}</h3>
            </Typography>
          </Grid>
        }

        {property.estValue !== 0 &&
          <Grid
            xs={4}
            item
            style={{ textAlign: 'center' }}
          >
            <Typography variant="body2" color="text.secondary">
              <h3>{`Est Value: $${property.estValue && property.estValue !== 0 && property.estValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h3>
            </Typography>
          </Grid>
        }

        {property.totalAssessment !== 0 &&
          <Grid
            xs={4}
            item
            style={{ textAlign: 'center' }}
          >
            <Typography variant="body2" color="text.secondary">
              <h3>{`Total Assmnt: $${property.totalAssessment && property.totalAssessment !== 0 && property.totalAssessment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</h3>
            </Typography>
          </Grid>
        }

        {property.yearOfConstruction !== 0 &&
          <Grid
            xs={4}
            item
            style={{ textAlign: 'center' }}
          >
            <Typography variant="body2" color="text.secondary">
              <h3>{`Const Year: ${property.yearOfConstruction}`}</h3>
            </Typography>
          </Grid>
        }

        <Grid
          xs={4}
          item
          style={{textAlign: 'center'}}
        >
          {property.hoa === 0 ?
            <Typography variant="body2" color="text.secondary">
              <h3>HOA: No</h3>
            </Typography>
            :
            <Typography variant="body2" color="text.secondary">
              <h3>{`HOA: $${property.hoa}`}</h3>
            </Typography>
          }
        </Grid>

        {property.pool === 'Yes' &&
          <Grid
            xs={4}
            item
            style={{textAlign: 'center'}}
          >
            <Typography variant="body2" color="text.secondary">
              <Grid 
                container
                direction="row"
              >
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={2}>
                  <PoolOutlinedIcon />
                </Grid>
                <Grid item xs={3}>
                  <h3>Pool:</h3>
                </Grid>
                <Grid item xs={3}>
                  <h3>{`${property.pool}`}</h3>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        }

        {property.parkingType &&
          <Grid
            xs={4}
            item
            style={{textAlign: 'center'}}
          >
            <Typography variant="body2" color="text.secondary">
              <h3>{`Parking: ${property.parkingType}`}</h3>
            </Typography>
          </Grid>
        }

      </Grid>
    </form>
  );
};

PropertyDetails.propTypes = {
  className: PropTypes.string
};

export default PropertyDetails;
