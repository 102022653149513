import React, { useState, useLayoutEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Header = () => {
  const [height, setHeight] = useState(150);
  const ref = useRef(null);

  let numbers = [1, 2, 3, 4, 5, 6, 7];
  numbers.sort(() => Math.random() - 0.5);

  const text = [
    'No more Cold Calling.',
    'Get rapid responses at scale and save on marketing costs.',
    'Find leads and convert it to Sales.',
    'Ready to grow your real estate profits?',
    'Generate more leads.',
    'Easy to contact prospects leads directly from the platform.',
    'No CRM? No problem.',
    'SMS Campaign Platform for Real Estate Investors.'
  ];

  const altText = [
    'real estate wholesaler',
    'cash buyers',
    'real estate',
    'fix and flip',
    'realtors',
    'sms campaign',
    'leads generator',
    'cold calling'
  ];

  const imgText = {
    color: 'white',
    background: 'rgba(0, 0, 0, 0.7)',
    padding: '20px',
    position: 'absolute',
    top: height < 400 ? 120 : (height / 2) - 50,
    left: 0,
    width: '100%'
  };

  useLayoutEffect(() => {
    if (ref.current.clientHeight !== null) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 1000);
    }
  }, []);

  return (
    <div className="carousel-wrapper" ref={ref}>
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showThumbs={false}
        interval={5000}
        showStatus={false}
      >
        {numbers.map(item => {
          return (
            <div>
              <img
                alt={ altText[item] }
                src={`/static/images/backgroundmobile${item}.jpg`}
              />
              <h1 style={imgText}>
                { text[item] }
              </h1>
            </div>
          )
        })}
      </Carousel>
    </div>
  );
};

export default Header;
