import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles 
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import axios from 'axios';
import pageData from '../../../pageData';
import { AppContext } from '../../../App';

import ProfilePicture from "profile-picture";
import "profile-picture/build/ProfilePicture.css";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const [width] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const profilePictureRef = useRef(null);

  const [userData, setUserData] = useContext(AppContext);
  const authUser = JSON.parse(userData);

  const [user, setUser] = useState({
    name: '',
    email: '',
    profilePic: ''
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGetUserInfo = async () => {
    const userInfo = new FormData();
    userInfo.append('userId', authUser.userId);

    await axios.post(`${pageData.apiUrl}/api/get-user-info`, userInfo)
      .then((res) => {     
        if (res.status === 200) {
          setUser({
            name: res.data.user.name,
            email: res.data.user.email,
            profilePic: res.data.user.profilePic
          });
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  const updateUserInfo = (img) => {
    setUserData(JSON.stringify({
      name: user.name,
      email: user.email,
      userId: authUser.userId,
      userType: authUser.userType,
      profilePic: img
    }));
  }

  const handleUpload = async () => {
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    setUser({
      ...user,
      profilePic: imageAsDataURL
    });

    setUserData(JSON.stringify({
      name: user.name,
      email: user.email,
      userId: authUser.userId,
      userType: authUser.userType,
      profilePic: ''
    }));
    
    setTimeout(() => {
      updateUserInfo(imageAsDataURL);
    }, 200)    
  
    const pictureData = new FormData();
    pictureData.append('userId', authUser.userId);
    pictureData.append('profilePic', imageAsDataURL);

    await axios.post(`${pageData.apiUrl}/api/upload-profile-pic`, pictureData)
      .then((res) => {
        if (res.status === 200) {
          console.log('Profile Picture already saved!');
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Avatar
              className={classes.avatar}
              src={user.profilePic !== '' ? user.profilePic : '' }
            />
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
              style={{marginTop: 30}}
            >
              {authUser.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {user.email}
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={handleClickOpen}
          >
            Upload picture
          </Button>
        </CardActions>
      </Card>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Profile Picture
        </DialogTitle>
        <DialogContent 
          dividers
          style={width > 500 ? {width: 450} : {width: 300}}
        >
          <ProfilePicture
            ref={profilePictureRef}
            frameFormat="circle"
            useHelper={true}
            debug={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose} 
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleUpload.bind(this)}
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
