const states = [
    { value: '', initials: '', codes: []  },
    { value: 'Alabama', initials: 'AL', codes: [205, 251, 256, 334, 938] },
    { value: 'Alaska', initials: 'AK', codes: [907] },
    { value: 'Arizona', initials: 'AZ', codes: [480, 520, 602, 623, 928] },
    { value: 'Arkansas', initials: 'AR', codes: [479, 501, 870] },
    { value: 'California', initials: 'CA', codes: [209, 213, 279, 310, 323, 408, 415, 424, 442, 510, 530, 559, 562, 619, 626, 628, 650, 657, 661, 669, 707, 714, 747, 760, 805, 818, 820, 831, 858, 909, 916, 925, 949, 951] },
    { value: 'Colorado', initials: 'CO', codes: [303, 719, 720, 970] },
    { value: 'Connecticut', initials: 'CT', codes: [203, 475, 860, 959] },
    { value: 'Delaware', initials: 'DE', codes: [302] },
    { value: 'Florida', initials: 'FL', codes: [239, 305, 321, 352, 386, 407, 561, 727, 754, 772, 786, 813, 850, 863, 904, 941, 954] },
    { value: 'Georgia', initials: 'GA', codes: [229, 404, 470, 478, 678, 706, 762, 770, 912] },
    { value: 'Hawaii', initials: 'HI', codes: [808] },
    { value: 'Idaho', initials: 'ID', codes: [208, 986] },
    { value: 'Illinois', initials: 'IL', codes: [217, 224, 309, 312, 331, 618, 630, 708, 773, 779, 815, 847, 872] },
    { value: 'Indiana', initials: 'IN', codes: [219, 260, 317, 463, 574, 765, 812, 930] },
    { value: 'Iowa', initials: 'IA', codes: [319, 515, 563, 641, 712] },
    { value: 'Kansas', initials: 'KS', codes: [316, 620, 785, 913] },
    { value: 'Kentucky', initials: 'KY', codes: [270, 364, 502, 606, 859] },
    { value: 'Louisiana', initials: 'LA', codes: [225, 318, 337, 504, 985] },
    { value: 'Maine', initials: 'ME', codes: [207] },
    { value: 'Maryland', initials: 'MD', codes: [240, 301, 410, 443, 667] },
    { value: 'Massachusetts', initials: 'MA', codes: [339, 351, 413, 508, 617, 774, 781, 857, 978] },
    { value: 'Michigan', initials: 'MI', codes: [231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989] },
    { value: 'Minnesota', initials: 'MN', codes: [218, 320, 507, 612, 651, 763, 952] },
    { value: 'Mississippi', initials: 'MS', codes: [228, 601, 662, 769] },
    { value: 'Missouri', initials: 'MO', codes: [314, 417, 573, 636, 660, 816] },
    { value: 'Montana', initials: 'MT', codes: [406] },
    { value: 'Nebraska', initials: 'NE', codes: [308, 402, 531] },
    { value: 'Nevada', initials: 'NV', codes: [702, 725, 775] },
    { value: 'New Hampshire', initials: 'NH', codes: [603] },
    { value: 'New Jersey', initials: 'NJ', codes: [201, 551, 609, 640, 732, 848, 856, 862, 908, 973] },
    { value: 'New Mexico', initials: 'NM', codes: [505, 575] },
    { value: 'New York', initials: 'NY', codes: [212, 315, 332, 347, 516, 518, 585, 607, 631, 646, 680, 716, 718, 838, 845, 914, 917, 929, 934] },
    { value: 'North Carolina', initials: 'NC', codes: [252, 336, 704, 743, 828, 910, 919, 980, 984] },
    { value: 'North Dakota', initials: 'ND', codes: [701] },
    { value: 'Ohio', initials: 'OH', codes: [216, 220, 234, 330, 380, 419, 440, 513, 567, 614, 740, 937] },
    { value: 'Oklahoma', initials: 'OK', codes: [405, 539, 580, 918] },
    { value: 'Oregon', initials: 'OR', codes: [458, 503, 541, 971] },
    { value: 'Pennsylvania', initials: 'PA', codes: [215, 223, 267, 272, 412, 445, 484, 570, 610, 717, 724, 814, 878] },
    { value: 'Rhode Island', initials: 'RI', codes: [401] },
    { value: 'South Carolina', initials: 'SC', codes: [803, 843, 854, 864] },
    { value: 'South Dakota', initials: 'SD', codes: [605] },
    { value: 'Tennessee', initials: 'TN', codes: [423, 615, 629, 731, 865, 901, 931] },
    { value: 'Texas', initials: 'TX', codes: [210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512, 682, 713, 726, 737, 806, 817, 830, 832, 903, 915, 936, 940, 956, 972, 979] },
    { value: 'Utah', initials: 'UT', codes: [385, 435, 801] },
    { value: 'Vermont', initials: 'VT', codes: [802] },
    { value: 'Virginia', initials: 'VA', codes: [276, 434, 540, 571, 703, 757, 804] },
    { value: 'Washington', initials: 'WA', codes: [206, 253, 360, 425, 509, 564] },
    { value: 'Washington, DC', initials: 'DC', codes: [202] },
    { value: 'West Virginia', initials: 'WV', codes: [304, 681] },
    { value: 'Wisconsin', initials: 'WI', codes: [262, 414, 534, 608, 715, 920] },
    { value: 'Wyoming', initials: 'WY', codes: [307] }
];
export default states;
