import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DvrIcon from '@material-ui/icons/Dvr';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText('#00FFFF'),
    backgroundColor: '#00FFFF',
    '&:hover': {
      backgroundColor: '#08D0CA'
    }
  },
}));

const imgText = {
  color: 'white',
  background: 'rgba(0, 0, 0, 0.7)',
  padding: 50,
  margin: 50,
  width: '80%',
  marginLeft: '10%'
};

const Pricing = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);

  console.log(width)

  return (
    <div style={{
      background: '#1F1F1F url(/static/images/online_payment.jpg) no-repeat center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      position: 'relative',
      overflow: 'hidden'
    }}
    >
      <div style={imgText}>

        <div style={{ fontFamily: 'Nanum Gothic', fontSize: 50, textAlign: 'center', color: '#76ff03' }}>
          PRICING
          <hr style={{ marginTop: 10, marginBottom: 20 }} />
        </div>

        <Grid container spacing={8} >

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h1 style={{ fontFamily: 'Nanum Gothic', textAlign: 'center', marginBottom: 30 }}>Pay As You Go</h1>

            <div style={{ fontFamily: 'Nanum Gothic', fontSize: 25 }}>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Weekly Fee
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $5.99
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Local Phone Number
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  1
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Extra Local Phone Number
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.50
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Free Daily Property Leads
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  20
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Extra Property Leads
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.01
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Full Propery Details
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.30
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  SMS Cost
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.020
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Forward Incoming Calls
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.01
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Included Balance
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $1.00
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ fontFamily: 'Nanum Gothic', fontSize: 30, textAlign: 'center', color: '#76ff03', marginTop: 20 }}>
                <Grid
                  item
                  xs={7}
                  sm={7}
                >
                  Weekly Cost
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={5}
                >
                  $5.99
                </Grid>
              </Grid>

              <div style={{ textAlign: 'center', marginTop: 30 }}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.button}
                  onClick={() => { navigate('/register', { replace: true }); }}
                >
                  SIGN UP HERE
                </Button>
              </div>

            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h1 style={{ fontFamily: 'Nanum Gothic', textAlign: 'center', marginBottom: 30 }}>Premium</h1>

            <div style={{ marginTop: 20, fontFamily: 'Nanum Gothic', fontSize: 25 }}>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Weekly Fee
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $15.99
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Local Phone Number
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  1
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Extra Local Phone Number
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.50
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Free Daily Property Leads
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  100
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Extra Property Leads
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.005
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Full Propery Details
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.25
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  SMS Cost
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.015
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Forward Incoming Calls
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $0.01
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={9}
                  sm={9}
                >
                  Included Balance
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={3}
                  style={{ textAlign: 'right' }}
                >
                  $10.00
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ fontSize: 30, textAlign: 'center', color: '#76ff03', marginTop: 20 }}>
                <Grid
                  item
                  xs={7}
                  sm={7}
                >
                  Weekly Cost
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={5}
                >
                  $15.99
                </Grid>
              </Grid>

              <div style={{ textAlign: 'center', marginTop: 30 }}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.button}
                  onClick={() => { navigate('/register', { replace: true }); }}
                >
                  SIGN UP HERE
                </Button>
              </div>

            </div>
          </Grid>
        </Grid >

      </div >
    </div >
  );
};

export default Pricing;
