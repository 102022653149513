import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import Tables from './Tables';
import PropertyDetails from './PropertyDetails';
import MainToolbar from './MainToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));

const StatisticListView = () => {
  const classes = useStyles();
  const [property, serProperty] = useState('');
  const [state, setState] = useState('FL');
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const handleSelectProperty = (propertySelected) => {
    serProperty(propertySelected);
  };

  const closeSelected = () => {
    serProperty('');
  };

  const handleByState = (text) => {
    setState(text);
  };

  const handleByCity = (text) => {
    setSelectedCity(text);
  };

  const handleCurrentCities = (cities) => {
    let currentCities = []
    cities.map(city => {
      currentCities.push({
        value: city,
        label: city
      })
    })
    setCity([]);
    setCity(currentCities);
    setSelectedCity(cities[0])
  }

  return (
    <Page
      className={classes.root}
      title="Find It Easy - Saved Properties"
    >
      <Container maxWidth={false}>
        {property === '' ? (
          <Box>
            {/* <MainToolbar
              searchbystate={(text) => handleByState(text)}
              searchbycity={(text) => handleByCity(text)}
              cities={city}
            /> */}
            <Tables
              selectProperty={(propertySelected) => handleSelectProperty(propertySelected)} 
              currentCities={(cities) => handleCurrentCities(cities)} 
              state={state}
              city={selectedCity}
            />
          </Box>
        )
          : (
            <Box>
              {/* <PropertyDetails property={property} closeMagic={() => closeSelected()} /> */}
            </Box>
          )}
      </Container>
    </Page>
  );
};

export default StatisticListView;
