import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Link,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TermUseView = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div style={{ width: '96%', marginLeft: '2%' }} >

      <div style={{ right: 40, top: 80, position: "absolute" }}>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          <Link
            component={RouterLink}
            to="/register"
            variant="h6"
            color="red"
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<CloseIcon />}
            >
              CLOSE
            </Button>
          </Link>
        </Typography>
      </div>

      <div style={{ marginTop: 30 }}>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Terms and Conditions of Service
        </Typography>
      </div>


      <Typography
        color="textPrimary"
      >
        <div style={{ marginTop: 14 }}>
          By visiting or using the www.finditeasy.us Web site (the "Web site"), or any co-branded and/or linking site, or by participating in any www.finditeasy.us sponsored educational program or seminar the user ("User") agrees to be bound by these Terms and Conditions of Service and that such agreement constitutes a binding contract between User and www.finditeasy.us. If User does not wish to be bound by these Terms and Conditions of Service, the User should not visit or use the www.finditeasy.us Web site.
        </div>
        <div style={{ marginTop: 14 }}>
          www.finditeasy.us reserves the right to change these Terms and Conditions of Service from time-to-time at its sole discretion. In the case of any violation of the Terms and Conditions of Service, www.finditeasy.us reserves the right to seek all remedies available by law and in equity for such violations. These Terms and Conditions of Service apply to all visits to the www.finditeasy.us Web site, to the education portion of the Web site both now and in the future.
        </div>
        <div style={{ marginTop: 14 }}>
          Your contact information (belonging to you, the subscriber) is collected by FindItEasy.us (ROSH HOMES LLC) on an opt-in basis and can be collected in different ways: FindItEasy.us (ROSH HOMES LLC) users can collect your first name, last name, phone number, email address and other custom data when you willingly subscribe/opt-in to FindItEasy.us (ROSH HOMES LLC) Online Sign-up Page. Additionally opt-in verification message will be sent to the email address or mobile phone number that you have provided. You must verify each piece of contact information to begin receiving messages via the listed communication channels. If you do not verify the contact information you have provided on the OSP, you will not receive messages from that channel. FindItEasy.us (ROSH HOMES LLC) may have previously collected your contact information on an opt-in basis outside of the FindItEasy.us (ROSH HOMES LLC)  system. This states that your contact information was collected with your permission and you have expressed consent to receive messages. All users have the ability to unsubscribe or change their personal subscription settings at any time.
        </div>
        <div style={{ marginTop: 14 }}>
          To unsubscribe from all future emails: Click the ‘Unsubscribe’ link located at the bottom of the email received.
        </div>
        <div style={{ marginTop: 14 }}>
          FindItEasy.us (ROSH HOMES LLC) may use or disclose information about you, including your personal information, under the following circumstances:
        </div>
        <ul style={{ marginLeft: 30, marginTop: 10 }}>
          <li>
            To anticipate and resolve problems and conflicts with our products and services.
          </li>
          <li>
            To provide you with products and services you requested.
          </li>
          <li>
            For verification and authentication purposes in regards to requests or changes of any personal information or data related.
          </li>
          <li>
            For other purposes communicated to you at the time you provide or authorize the use of your information.
          </li>
          <li>
            To enforce our Terms of Use and Privacy Policy or other legal agreements.
          </li>
          <li>
            To outsource any of the tasks referenced in this Terms of Use and Privacy Policy, such as customer service.
          </li>
          <li>
            In response to a subpoena, court order, or other legal process.
          </li>
          <li>
            To establish or exercise our legal rights or defend against legal claims.
          </li>
          <li>
            To share with a company controlled by, or under common control with FindItEasy.us (ROSH HOMES LLC) for any purpose permitted by our Terms of Use and Privacy Policy.
          </li>
          <li>
            When FindItEasy.us (ROSH HOMES LLC) believes such use or disclosure is (a) necessary in order to investigate, prevent, or take action regarding suspected illegal activities, fraud, or situations involving potential threats to the physical safety of any person, or (b) required by law.
          </li>
          <li>
            In the event of a merger, acquisition, consolidation, divestiture, or bankruptcy of FindItEasy.us (ROSH HOMES LLC).
          </li>
        </ul>
        <div style={{ marginTop: 14 }}>
          <strong>No Endorsements; User Waiver of Claims</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          User acknowledges that www.finditeasy.us sells no real estate or real estate services ("Product") through this Web site. Certain real estate brokers, lenders, educational providers, speakers, seminar agents or other real estate or educational service providers (collectively, the "Vendors") may post information on this Web site or contact you at your request, but User understands that www.finditeasy.us is not affiliated with any such Vendors and in no way endorses or recommends any particular Vendor. User is in no way obligated, required or encouraged to utilize any of the Vendors posting information on this Web site, including those providing educational, seminar or other published materials. If User decides to utilize a Vendor, any transaction, offer to buy or sell or related communication conducted on this site is solely between User and Vendor. The exclusive remedy arising from communications on or through this Web site is between User and relevant Vendor. User agrees that, in return for permission to use the Web site, he or she waives any and all claims against www.finditeasy.us that are connected to use of this Web site or any vendor that provides services or products on this Web site.
        </div>
        <div style={{ marginTop: 14 }}>
          <strong>General Disclaimer</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          Although www.finditeasy.us has made reasonable efforts to place accurate information on this Web site, www.finditeasy.us assumes no responsibility for the accuracy of the information. www.finditeasy.us PROVIDES THIS WEB SITE CONTENT "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, TO THE FULLEST EXTENT ALLOWED BY LAW. FURTHER, ANY EDUCATIONAL MATERIAL, SEMINAR MATERIAL OR ANY OTHER REFERENCE IS PROVIDED "AS IS" WHERE IS AND WWW.FINDITEASY.US FURTHER DISCLAIMS ALL OTHER WARRANTIES, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR THE CURRENT ACCURACY OF EDUCATIONAL OR OTHER MATERIAL AS BEING CURRENT INFORMATION OR INFORMATION THAT MAY BE RELIED ON TO MAKE BUSINESS OR OTHER DECISIONS WWW.FINDITEASY.US DOES NOT WARRANT UNINTERRUPTED OR ERROR-FREE FUNCTIONS CONTAINED IN THIS SITE OR ITS CONTENT OR THAT THE WEB SITE OR ITS SERVER ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </div>
        <div style={{ marginTop: 14 }}>
          <strong>Limitation of Liability</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          WWW.FINDITEASY.US SHALL NOT BE LIABLE FOR DIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES RESULTING FROM THE USE OR INABILITY TO USE THIS SITE, FOR EDUCATIONAL MATERIAL SOLD OR DISTRIBUTED ON THIS SITE OR AS CONTAINED IN SEMINARS OR EDUCATIONAL CLASSES PROVIDED AT THIRD-PARTY LOCATIONS WHETHER OR NOT WWW.FINDITEASY.US HAS BEEN ADVISED OF THE POSSIBILITY OF THESE DAMAGES. THIS INCLUDES DAMAGES FOR NEGLIGENCE OR GROSS NEGLIGENCE.
        </div>
        <div style={{ marginTop: 14 }}>
          <strong>Indemnification</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          User agrees to indemnify and hold harmless www.finditeasy.us, at User's sole expense, against any claim, action, legal proceeding, damages, liability, settlements, expenses (including attorneys' fees) and other costs relating to (i) breach of any of these terms by any party; (ii) User's negligence or misconduct, or (iii) claims that User has infringed the copyright, trademark, trade secret, patent, or other proprietary right of a third party.
        </div>
        <div style={{ marginTop: 14 }}>
          <strong>Copyright/Restrictions on Use of Material</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          The subscription to www.finditeasy.us services is intended for individual or private use only. The commercial resale, electronic extraction or reformatting of information available on the www.finditeasy.us Web site or on any other co-branded Web site, by any subscriber in any capacity, is strictly prohibited and a material condition to the use of the www.finditeasy.us site. The same shall be acknowledged to be copyright infringement on the valid filed or unfiled copyrighted material of www.finditeasy.us, which constitutes the intellectual property of ROSH HOMES LLC. Use of this Web site constitutes the uncontroverted acknowledgement of the copyrighted material contained therein. Data mining, screen scraping, copying graphics, maps, designs, logos or downloading and compiling data or any other activity designed to obtain, re-use, reformat, re-sell the proprietary or other foreclosure information contained within the www.finditeasy.us or co-branded Web sites in a commercial manner is strictly prohibited. www.finditeasy.us reserves the right to immediately terminate any account that is being used in such activity without notice. The reposting, manipulation, display or other Web-based presentation of data obtained through the www.finditeasy.us Web site (or co-branded Web site) on the Internet or through email is strictly prohibited and may constitute copyright, trademark or service mark infringement. In the event that any subscriber is determined to be engaged in any activities prohibited in this section, www.finditeasy.us may seek injunction in any court located in Miami Dade County, Florida or the United States District Court for The Southern District of Florida. And, the subscriber through the use of this site consents to the jurisdiction of said courts and shall and agree to be liable for all attorneys fees incurred as the result of any enforcement of any injunction for legal action related to any wrongful action referenced herein.
        </div>
        <ol style={{ marginLeft: 30, marginTop: 10 }}>
          <li>
            Customer shall always use the real property ownership information for the Customer's own internal business purposes only;
          </li>
          <li>
            Customer shall not use the real property ownership information for reproduction, sale, publication or any other use in relation to any product or service to be provided to any third party, or any other commercial exploitation;
          </li>
          <li>
            Customer shall acquire no proprietary rights in or to the real property ownership information or in any data contained therein, which rights remain the Licensee and its data suppliers;
          </li>
          <li>
            Customer shall not disclose or provide the real property ownership information to any affiliates or parent organization if they do not have a need to know for the purposes permitted in the Customer Agreement or are otherwise covered under the Customer Agreement;
          </li>
          <li>
            Customer acknowledges that Licensee Reports, as well as the data contained therein, is sourced from public documents or statistical calculations and is provided on an "as is, as available" basis with all faults and defects. The data supplier does not make any warranties, expressed or implied, including without limitation, those of merchantability and fitness for a particular purpose, nor is the data supplier responsible for errors, omissions, miscalculations, or misrepresentations of value;
          </li>
          <li>
            Customer acknowledges that the real property ownership information is proprietary information of Licensee and suppliers, under copyright, and has been furnished to Customer in trust. Customer acknowledges that the information is a valuable commercial product, the development of which has involved the expenditure of substantial time and money. Customer will issue appropriate instructions to all of its employees having access to the Licensee Reports concerning the restrictions contained herein, and shall initiate reasonable security measures to prevent the accidental or otherwise unauthorized use or release of the real property ownership information, with particular respect to consumer information that is or may be considered "sensitive" such as financial or personally identifiable information, including but not limited to, owner name, mortgage values, sale amounts, estimated property values, real estate loan information, default, and foreclosure information, as related to a named individual owner;
          </li>
          <li>
            Customer acknowledges that automated valuation models attempt to estimate the current market value of a piece of residential real property using technology. An estimated value is not an appraisal and neither Licensee nor its suppliers or licensors make any warranties expressed or implied that any estimated property values associated with the service or as contained in any report are complete, guaranteed as accurate, fit for a particular purpose, or merchantable. Any estimated values are delivered "as is," with all faults and defects. Use of estimated property values is done so that the sole risk and responsibility of Customer or the ultimate end user of such values. In no event shall Licensee or its suppliers or licensors be liable for use or misuse of estimated values, any use or misuse in violation of any law, regulation, or industry standard, the inability or failure of a person or entity to conduct business, or for any indirect, special or consequential damages related to use or misuse of an estimated value regardless of the circumstances;
          </li>
          <li>
            Any use of the service and underlying real property information by Customer shall be at its own risk, and Customer hereby indemnifies Licensee and its suppliers or licensors with respect thereto. In no event shall Licensee and its suppliers or licensors be responsible for any errors, omissions, miscalculations, or misrepresentations of value;
          </li>
          <li>
            Licensee's data supplier is not responsible for any damages, including but not limited to, those incurred as a result of lost profits or revenue, loss of use of the Licensee Reports, loss of any substitute data or claims by third parties, or for other similar costs. In no event shall Licensee's data supplier be liable for any damages resulting from Customer's inability or failure to access any Web site;
          </li>
          <li>
            Customer acknowledges that certain states and federal regulatory bodies have enacted laws that place restrictions on marketing activities, including but not limited to, use of public record information in connection with mortgage lending or other financial services mail marketing, or permitting a telephone customer to give public notice that such customer does not wish to receive sales solicitation telephone calls. Licensee and its data suppliers disclaim any warranty, expressed or implied, that the real property information is free from any consumer information that may give rise to a privacy claim.
          </li>
        </ol>
        <div style={{ marginTop: 14 }}>
          <strong>Trademarks, Service Marks, Logos, Corporate Identifications Protected</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          All trademarks, service marks, logos, or other corporate identifications (collectively, "Marks") on this Web site are trademarks or service marks of www.finditeasy.us or ROSH HOMES LLC. User agrees not to use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Marks in any way. The use of these Marks on or as a link to any other Web site is strictly prohibited without prior written approval by www.finditeasy.us.
        </div>
        <div style={{ marginTop: 14 }}>
          <strong>User's Code of Behavior</strong>
        </div>
        <div style={{ marginTop: 14 }}>
          User agrees not to post on the Web site any content that (a) is libelous, defamatory, obscene, pornographic, abusive, harassing, or threatening, (b) contains viruses or other contaminating or destructive features, (c) violates the rights of others, including protections of copyright, trademark, patent, trade secrets or any right of privacy or publicity, or (d) otherwise violates any applicable law. User will not post on this Web site any links to any external Internet sites that are obscene or pornographic. Any information that you provide to www.finditeasy.us must be true, complete and accurate, must not represent you as someone else or falsely identify any third party, and must not violate any law, statute, ordinance or regulation.
        </div>
        <div style={{ marginTop: 14 }}>
          Usage of the subject site shall be limited to non-robotic, non-mechanical or other non-artificial/software based searches, which shall not exceed 200 searches per 24-hour period. Violation of this use policy subjects the user to immediate termination of service without refund or pro-rated credit for any subscription fee.
        </div>
        <div style={{ marginTop: 14, paddingBottom: 40 }}>
          By subscribing to the www.finditeasy.us service, User agrees not to disclose their username (email) and password combination to others. User also agrees not to distribute the information contained in www.finditeasy.us to any person or organization, by any means, printed, electronic or other.
        </div>
      </Typography>
    </div>
  );
};

export default TermUseView;
