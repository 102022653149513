import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';

import axios from 'axios';
import pageData from '../../../pageData';

const useStyles = makeStyles({
    chatSection: {
        width: '100%',
        height: window.innerHeight - 355
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '100%',
        overflowY: 'auto'
    }
});

const Chat = ({ className, ...rest }) => {
    const classes = useStyles();
    const [sms, setSms] = useState([]);
    const [values, setValues] = useState({
        message: '',
        smsLength: 0
    });
    const [open, setOpen] = useState(false);
    const [response, setResponse] = useState(3);
    const [title, setTitle] = useState(quickResponse[3].title)

    const fetchSms = async () => {
        const smsData = new FormData();
        smsData.append('smsId', rest.smsId);

        await axios.post(`${pageData.apiUrl}/api/get-sms-by-id`, smsData)
            .then((res) => {
                if (res.status === 200) {
                    setSms(res.data.sms);
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        fetchSms();
    }, [rest.smsId]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            smsLength: event.target.value.length
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeResponse = (event) => {
        // console.log(quickResponse[event.target.value])
        setResponse(event.target.value);
        setTitle(quickResponse[event.target.value].value)
    };

    return (
        <div>
            <Grid container className={classes.chatSection}>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'left', margin: '5px 0px 10px 20px' }}>
                        {sms.message && <h2>{`(${sms.toNumber.substring(2, 5)}) ${sms.toNumber.substring(5, 8)}-${sms.toNumber.substring(8, 12)}`}</h2>}
                    </div>

                    <Divider />

                    {sms.message &&
                        <List className={classes.messageArea}>

                            {sms.message.map(item => {
                                return <ListItem
                                    key={item.sid}
                                    style={item.direction === "outbound" ?
                                        { backgroundColor: '#E8F8F5', borderRadius: '15px 15px 0px 15px', marginBottom: 7, width: '90%', marginLeft: '10%' } :
                                        { backgroundColor: '#FDEDEC', borderRadius: '15px 15px 15px 0px', marginBottom: 7, width: '90%', marginRight: '10%' }
                                    }>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ListItemText
                                                align={item.direction === "outbound" ? "right" : "left"}
                                                primary={item.text}></ListItemText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ListItemText align={item.direction === "outbound" ? "right" : "left"} secondary={moment(item.date).fromNow()}></ListItemText>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            })}
                        </List>
                    }

                    <Divider />

                    <Grid container style={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}>
                        <Grid item xs={7}>
                            <Button
                                variant="outlined"
                                endIcon={<QuickreplyOutlinedIcon />}
                                color="success"
                                onClick={handleClickOpen}
                            >
                                Quick Response
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="outlined"
                                endIcon={<DoDisturbAltOutlinedIcon />}
                                color="error"
                            >
                                Quit
                            </Button>
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container style={{ padding: '10px' }}>
                        <Grid item xs={10}>
                            <TextField
                                id="message"
                                variant="outlined"
                                label="Type Something"
                                multiline
                                rows={4}
                                fullWidth
                                name="message"
                                onChange={handleChange}
                                value={values.message}
                            />
                        </Grid>
                        <Grid xs={2} align="right">
                            <div style={{ textAlign: 'center', paddingLeft: 10 }}>
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    size="small"
                                    style={{ marginTop: 20 }}
                                    disabled={values.smsLength > 0 && values.smsLength < 161 ? false : true}
                                >
                                    <SendOutlinedIcon />
                                </Fab>
                                <p style={{ marginTop: 15 }}>
                                    {values.smsLength}<strong>/160</strong>
                                </p>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>            

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
            >
                <DialogTitle style={{ textAlign: 'center', marginTop: 20 }}>
                <h1>{title}</h1>
                
                    <TextField
                        label="Response"
                        name="response"
                        onChange={handleChangeResponse}
                        select
                        SelectProps={{ native: true }}
                        value={title}
                        variant="outlined"
                        style={{ width: '60%' }}
                    >
                        {quickResponse.map((option) => (
                            <option
                                key={option.id}
                                value={option.value}
                                // value={option.value}
                            >
                                {option.title}
                            </option>
                        ))}
                    </TextField>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {quickResponse[response].text}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>

            </Dialog>
        </div >
    );
}

export default Chat;

const name = 'Roger';
const company = 'Rosh Homes LLC';
const owner = 'TESTING';

const quickResponse = [
    {
        id: 0,
        value: 'Yes, you have the right person.',
        title: 'Yes, you have the right person.',
        text: `Great! I was hoping I had reached the right person. I am looking for another property to purchase in the area. Have you given any thought about sellign it?`
    },
    {
        id: 1,
        value: 'Wrong number.',
        title: 'Wrong number.',
        text: `Oh okay well sorry to bother you. Do you happen to have any properties that you are looking to sell?`
    },
    {
        id: 2,
        value: 'No interested in selling.',
        title: 'No interested in selling.',
        text: `Oh ok. I understand. Is this something you might reconsider in say 1-3 months or 3-6 months?  Thank you.`
    },
    {
        id: 3,
        value: 'Who am I?',
        title: 'Who am I?',
        text: `I'm ${name} with ${company}. I'm just curious if ever you have any interest in selling your property? Thank you.`
    },
    {
        id: 4,
        value: 'Who is this?',
        title: 'Who is this?',
        text: `My name is ${name} and I'm looking for another rental or rehab project and I like the are. I've also reached out to some others in your neighborhood asking if they are interested.`
    },
    {
        id: 5,
        value: 'Ask for call.',
        title: 'Ask for call.',
        text: `I will, thanks!`
    },
    {
        id: 6,
        value: 'Ask for condition of the property.',
        title: 'Ask for condition of the property.',
        text: `Can you please tell me about the condition of the property? Have you made any updates in the last couple of years? Any repairs needed? Thanks!`
    },
    {
        id: 7,
        value: 'What is you offer?',
        title: 'What is you offer?',
        text: `I'd prefer not to throw out a number before I know a little more about your property. Have you made any updates in the last 2 years? Any repairs needed? Thanks.`
    },
    {
        id: 8,
        value: 'Asking for retail.',
        title: 'Asking for retail.',
        text: `We don't really buy in retail. If you could consider sellign at a discounted price with cash and could close quick please let me know. Thank you.`
    },
    {
        id: 9,
        value: 'Asking price?',
        title: 'Asking price?',
        text: `What price would you need to make a deal on the property?`
    },
    {
        id: 10,
        value: 'Asking to call.',
        title: 'Asking to call.',
        text: `Sounds good to me, but so not to waste your time, what is your asking price for the peroperty? And how soon are you looking to sell? Thanks!`
    },
    {
        id: 11,
        value: 'Property already listed.',
        title: 'Property already listed.',
        text: `Oh, the property is listed already. Great, I'll look it up and reach out to the listing agent if interested still. Thanks, have a great rest of your day!`
    },
    {
        id: 12,
        value: 'Keep me in mind.',
        title: 'Keep me in mind.',
        text: `I understand, please keep me in ming if anything changes for you. Thanks ${owner}, have a great rest of your day.`
    },
    {
        id: 13,
        value: 'How did you get my number?',
        title: 'How did you get my number?',
        text: `I cross-reference the property address of the houses I'm interested in with public records and use an online service to help locate the property owners.`
    },
    {
        id: 14,
        value: 'Schedule a call.',
        title: 'Schedule a call.',
        text: `I'd like to get a little more info about the property - happy to answer any questions you have. Is there a time today or tomorrow I can give you a quick call?`
    },
    {
        id: 15,
        value: 'Asking price to high.',
        title: 'Asking price to high.',
        text: `I'm sorry ${owner}, I cannot meet your price expectations. If anything changes for you, please keep me in mind. Have a great day:)`
    },
    {
        id: 16,
        value: 'It is leased / rented until ?',
        title: 'It is leased / rented until ?',
        text: `I'm actually looking for another rental, would you have any interest in selling sooner if I could take over the lease?`
    },
    {
        id: 17,
        value: 'Check comparables',
        title: 'Check comparables',
        text: `I'll take a good look at the comparable sales and follow up with you if I can make that work. Thanks ${owner}, have a great rest of our day!`
    },
    {
        id: 18,
        value: 'Already under contract?',
        title: 'Already under contract?',
        text: `Thanks for letting me know. If anything happends to fall apart with your current buyer please let me know as I have some interest in this property as well.`
    }
];