import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { AppContext } from '../../../App';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import axios from 'axios';
import pageData from '../../../pageData';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  buttonDelete: {
    color: '#b71c1c',
    background: '#fce4ec',
    marginRight: theme.spacing(1)
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: '#D6EAF8'
    }
  },
  tableCell: {
    "$selected &": {
      color: "yellow"
    }
  },
  selected: {}
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();

  const [data] = useContext(AppContext);
  const authUser = JSON.parse(data);

  const [messages, setMessages] = useState([]);
  const [sms, setSms] = useState([]);

  const fetchSmss = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);

    await axios.post(`${pageData.apiUrl}/api/get-all-user-sms`, userData)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.smss.map((sms) => {
            data.push({
              smsId: sms._id,
              number: `(${sms.toNumber.substring(2, 5)}) ${sms.toNumber.substring(5, 8)}-${sms.toNumber.substring(8, 12)}`,
              message: sms.lastMessage.length > 110 ? `${sms.lastMessage.slice(0, 110)} .....` : sms.lastMessage,
              lastModify: sms.lastModify
            });
            return true;
          });
          setMessages(data);
          makeSelection(data[0].smsId)
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchSmss();
  }, []);

  const makeSelection = (smsId) => {
    setSms(smsId);
    rest.selectSms(smsId);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box>
          <TableContainer sx={{ maxHeight: window.innerHeight - 115 }}>
            <Table>
              <TableBody>
                {messages.map((message) => {
                  return <TableRow
                    hover
                    key={message.smsId}
                    onClick={() => makeSelection(message.smsId)}
                    selected={message.smsId === sms}
                    classes={{ selected: classes.selected }}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      <Typography gutterBottom variant="body1" component="div">
                        {message.smsId === sms ? <b>{message.number}</b> : message.number}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {message.smsId === sms ? <b>{message.message}</b> : message.message}
                      </Typography>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                      {message.smsId === sms ? <b>{moment(message.lastModify).fromNow()}</b> : moment(message.lastModify).fromNow()}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
