import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import axios from 'axios';
import pageData from '../../../pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonClose: {
    color: '#b71c1c',
    background: '#fce4ec',
    marginLeft: theme.spacing(1)
  },
  buttonSave: {
    color: '#0d47a1',
    background: '#e3f2fd',
    marginLeft: theme.spacing(1)
  },
}));

const ContactDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [contact, setContact] = useState({});
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    number: '',
    street: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const fetchContacts = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-contact-by-id/${rest.contact}`)
      .then((res) => {
        if (res.status === 200) {
          setContact(res.data.contact);
          setValues({
            firstName: res.data.contact.firstName,
            lastName: res.data.contact.lastName,
            number: res.data.contact.number,
            street: res.data.contact.street.replace(/[!#&().-]/gi, ''),
            unitNumber: res.data.contact.unitNumber.split('BOX')[0].replace(/[!#&().-]/gi, '').trim(),
            city: res.data.contact.city,
            state: res.data.contact.state,
            zipCode: res.data.contact.zipCode
          });
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchSaveGoodContact = async () => {
    /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
    const contactData = new FormData();
    contactData.append('contactId', contact._id);
    contactData.append('firstName', values.firstName);
    contactData.append('lastName', values.lastName);
    contactData.append('number', values.number);
    contactData.append('street', values.street.trim());
    contactData.append('unitNumber', values.unitNumber.trim());
    contactData.append('city', values.city);
    contactData.append('state', values.state);
    contactData.append('zipCode', values.zipCode);
    contactData.append('mailingAddress', `${values.number} ${values.street} APT ${values.unitNumber}, ${values.city}, ${values.state} ${values.zipCode}`);

    await axios.post(`${pageData.apiUrl}/api/save-contact`, contactData)
      .then((res) => {
        if (res.status === 200) {
          // Close this view and send me back to the original property table
          ToastsStore.success(res.data.message);
          rest.closeContact();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Card>
        <CardHeader
          title="Contact Details"
        />
        <Divider />
        <CardContent>
          {/* ----------  NAME  -------- */}
          <Grid
            container
            spacing={1}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="First Name"
                margin="normal"
                name="firstName"
                onChange={handleChange}
                type="text"
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last Name"
                margin="normal"
                name="lastName"
                onChange={handleChange}
                type="text"
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {/* ----------  ADDRESS  -------- */}
          <Grid
            container
            spacing={1}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Number"
                margin="normal"
                name="number"
                onChange={handleChange}
                type="text"
                value={values.number}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Street"
                margin="normal"
                name="street"
                onChange={handleChange}
                type="text"
                value={values.street}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Apt"
                margin="normal"
                name="unitNumber"
                onChange={handleChange}
                type="text"
                value={values.unitNumber}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="City"
                margin="normal"
                name="city"
                onChange={handleChange}
                type="text"
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="State"
                margin="normal"
                name="state"
                onChange={handleChange}
                type="text"
                value={values.state}
                variant="outlined"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Zip Code"
                margin="normal"
                name="zipCode"
                onChange={handleChange}
                type="text"
                value={values.zipCode}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            className={classes.buttonSave}
            variant="contained"
            startIcon={<SaveOutlinedIcon />}
            onClick={() => fetchSaveGoodContact()}
          >
            Update Contact
          </Button>
          <Button
            className={classes.buttonClose}
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={() => rest.closeContact()}
          >
            CLOSE
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ContactDetails.propTypes = {
  className: PropTypes.string
};

export default ContactDetails;
