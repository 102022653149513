import React, { Component } from 'react';

class WhyUsMob extends Component {
  render() {
    return (
      <div style={{
        background: '#545151',
        overflow: 'hidden'
      }}
      >
        <div style={{ width: '92%', marginLeft: '4%', marginTop: 25, fontFamily: 'Nanum Gothic', fontSize: 28, color: 'white' }}>
          Why <strong>Find It Easy</strong>, SMS Lead Generation.
        </div>

        <div style={{ width: '92%', marginLeft: '4%', marginTop: 20, fontFamily: 'Nanum Gothic', fontSize: 20, color: '#E8E4E2' }}>
          SMS campaigns have extremely high open and opt-in rates, and help you reach a broad customers base. Not everyone has a smartphone, but virtually everyone has a phone with texting capabilities.
        </div>

        <div style={{ width: '92%', marginLeft: '4%', marginTop: 20, fontFamily: 'Nanum Gothic', fontSize: 20, color: '#E8E4E2' }}>
          Also, SMS Messages have a 95 percent open rate, compared to just 11 percent for email marketing or answered cold calls. This means you can be sure your message doesn’t get lost or ignored.
        </div>

        <div style={{ width: '92%', marginLeft: '4%', marginTop: 20, fontFamily: 'Nanum Gothic', fontSize: 20, color: '#E8E4E2' }}>
          SMS is one of the most immediate channels available; with a read rate of 97% within 15 minutes delivery, you can be reassured that your time critical messages will be read almost instantly. A significant 45% of SMS campaigns generate a successful ROI, reaching over 50% when combined with other popular channels such as email and social media.
        </div>

        <div style={{ width: '92%', marginLeft: '4%', marginTop: 20, fontFamily: 'Nanum Gothic', fontSize: 20, color: '#E8E4E2' }}>
          Your business needs new motivated sellers to grow, that’s why new leads are so important. Unfortunately for many Real Estate Investors, this crucial part of their job is also one of the hardest. Endless hours of cold calling with no results can be a frustrating and demoralizing waste of time.
        </div>

        <div style={{ width: '92%', marginLeft: '4%', marginTop: 20, marginBottom: 25, fontFamily: 'Nanum Gothic', fontSize: 20, color: '#E8E4E2' }}>
          If this sounds like you, Find It Easy is your solution.
        </div>
      </div>
    );
  }
}

export default WhyUsMob;
