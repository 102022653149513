import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

import axios from 'axios';
import pageData from '../../../pageData';

const columns = [
  {
    name: 'propertyId',
    label: 'Address',
    options: {
      display: false,
    }
  },
  {
    name: 'address',
    label: 'Address',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'zipCode',
    label: 'Zipcode',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'owner',
    label: 'Owner',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'bedrooms',
    label: 'Beds',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'bathrooms',
    label: 'Baths',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'lastSale',
    label: 'Last Sale',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'estValue',
    label: 'Est Value',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'estBalance',
    label: 'Remaining Balance',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'estEquity',
    label: 'Est Equity',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'lienAmount',
    label: 'Lien Amount',
    options: {
      filter: false,
      sort: true,
    }
  },
];

const Tables = ({ ...rest }) => {
  const [properties, setProperties] = useState([]);

  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none',
    onRowClick: (rowData) => rest.selectProperty(rowData[0])
  };

  const fetchExperts = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-some-properties`)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.properties.map((property) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
            data.push({
              propertyId: property._id,
              address: property.address,
              city: property.city,
              state: property.state,
              zipCode: property.zipCode,
              owner: `${property.ownerFirstName1} ${property.ownerLastName1}`,
              bedrooms: property.bedrooms,
              bathrooms: property.totalBathrooms,
              lastSale: property.lastSaleAmount === null ? '-' : `$${property.lastSaleAmount}`,
              estValue: `$${property.estValue}`,
              estBalance: `$${property.estRemainingBalanceOfOpenLoans}`,
              estEquity: `$${property.estEquity}`,
              lienAmount: property.lienAmount === '' ? '-' : `$${parseInt(property.lienAmount, 10)}`
            });
            return true;
          });
          setProperties(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  return (
    <MUIDataTable
      title="All Properties"
      data={properties}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
