import React, { useState, useContext } from 'react';

import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardCVVInput,
  CreditCardSubmitButton
} from 'react-square-payment-form';
import 'react-square-payment-form/lib/default.css';

import PropTypes from 'prop-types';

import { AppContext } from '../../../App';
import axios from 'axios';
import pageData from '../../../pageData';

const Payment = ({ className, ...rest }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);

  const cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
    if (errors) {
      setErrorMessages(errors.map(error => error.message))
      return
    }

    setErrorMessages([])
    // alert("nonce created: " + nonce)

    handleValidateCode({sourceId: nonce, cardData: cardData});
  }

  const handleValidateCode = async (req) => {
    const userInfo = new FormData();
    userInfo.append('userId', userData.userId);
    userInfo.append('sourceId', req.sourceId);
    userInfo.append('address', rest.userInfo.address);
    userInfo.append('unitNumber', rest.userInfo.unitNumber);
    userInfo.append('city', rest.userInfo.city);
    userInfo.append('state', rest.userInfo.state);
    userInfo.append('zipCode', rest.userInfo.zipCode);
    userInfo.append('name', rest.userInfo.name);
    userInfo.append('email', userData.email);
    userInfo.append('expMonth', req.cardData.exp_month);
    userInfo.append('expYear', req.cardData.exp_year);
    userInfo.append('subscription', rest.plan);

    await axios.post(`${pageData.apiUrl}/api/pay-subscription`, userInfo)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data)
          if (res.data.success) {
            rest.paySubscription();
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return (
    <div style={{ textAlign: 'center', marginTop: 10, margin: '10 auto', width: '100%' }}> 
      <SquarePaymentForm
        sandbox={true}
        postalCode={false}

        // SANDBOX
        applicationId='sandbox-sq0idb-PWCrGtjDrBYZMW2p17eRlg'
        locationId='L77HHW443KDG1'

        // PRODUCTION
        // applicationId='sq0idp-pokx17BvdV8S94fV58Eb3g'
        // locationId='85BAHT71T214A'

        cardNonceResponseReceived={cardNonceResponseReceived}
      >
        {/* Make a different layout for mobile view, I need to edit react-square-payment-form in node_modules */}
        <fieldset className="sq-form-fields">
          <div className="sq-form-card-number">
            <CreditCardNumberInput />
          </div>
          <div className="sq-form-card-expiration">
            <CreditCardExpirationDateInput />
          </div>
          <div className="sq-form-card-cvv">
            <CreditCardCVVInput />
          </div>
        </fieldset>

        <CreditCardSubmitButton>
          {`Pay $${ rest.plan === 'PayAsGo' ? 5 : 15 }.99`}         
        </CreditCardSubmitButton>
      </SquarePaymentForm>

      <div className="sq-error-message">
        {errorMessages && errorMessages.map(errorMessage =>
          <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
        )}
      </div>
    </div>
  );
};

Payment.propTypes = {
  className: PropTypes.string
};

export default Payment;
