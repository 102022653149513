import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from '../layouts/DashboardLayout';

import MainLayout from '../layouts/MainLayout';
// import AccountView from '../views/account/AccountView';
import UsersListView from '../views/users/UsersListView';
import PropertyListView from '../views/properties/AllProperties';
import ContactListView from '../views/contacts/AllContacts';
import DashboardView from '../views/dashboard/AdminView';
import NotFoundView from '../views/errors/NotFoundView';
// import ProductListView from '../views/product/ProductListView';
// import SettingsView from '../views/settings/SettingsView';
// import ExpertsListView from '../views/experts/AllEAS';
// import SymbolListView from '../views/symbol/AllSymbols';
// import TransactionsAllListView from '../views/transactions/TransactionsAllListView';
// import DemoAccountList from '../views/demo/Accounts';
import Web from '../views/page';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'users', element: <UsersListView /> },
      { path: 'properties', element: <PropertyListView /> },
      { path: 'contacts', element: <ContactListView /> },

      // { path: 'account', element: <AccountView /> },
      // { path: 'products', element: <ProductListView /> },
      // { path: 'settings', element: <SettingsView /> },
      // { path: 'experts', element: <ExpertsListView /> },
      // { path: 'demoaccounts', element: <DemoAccountList /> },
      // { path: 'transactions', element: <TransactionsAllListView /> },
      // { path: 'symbols', element: <SymbolListView /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'web', element: <Web /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: 'login', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '404', element: <NotFoundView /> },
    ]
  }
];

export default routes;
