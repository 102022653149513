import React, { useState } from 'react';
import {
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from '../../components/Page';

import GoToPortal from './GoToPortal';
import Header from './Header';
import HeaderMob from './HeaderMob';
import WhyUs from './WhyUs';
import WhyUsMob from './WhyUsMob';
import Pricing from './Pricing';
import PricingMob from './PricingMob';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  }
}));

const Web = () => {
  const classes = useStyles();
  const [width] = useState(window.innerWidth);

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      {width > 500
        ? (
          <>
            <div
              style={{
                display: 'block',
                float: 'right',
                zIndex: 3,
                position: 'absolute',
                right: '50px',
                top: '80px'
              }}
            >
              <GoToPortal />
            </div>
            <Grid>
              <Grid>
                <Header />
              </Grid>

              <Grid>
                <WhyUs />
              </Grid>

              <Grid>
                <Pricing />
              </Grid>

              <Grid>
                <Footer />
              </Grid>
            </Grid>
          </>
        )
        : (
          <>
            <div
              style={{
                display: 'block',
                float: 'right',
                zIndex: 3,
                position: 'absolute',
                right: '20px',
                top: '80px'
              }}
            >
              <GoToPortal />
            </div>
            <Grid>
              <Grid>
                <HeaderMob />
              </Grid>
              <Grid>
                <WhyUsMob />
              </Grid>
              <Grid>
                <PricingMob />
              </Grid>
              <Grid>
                <Footer />
              </Grid>
            </Grid>
          </>
        )}
    </Page>
  );
};

export default Web;
