const cities = {

    AZ: [
        {
            "count": 4,
            "city": "ANTHEM"
        },
        {
            "count": 14,
            "city": "AVONDALE"
        },
        {
            "count": 28,
            "city": "BUCKEYE"
        },
        {
            "count": 8,
            "city": "CAVE CREEK"
        },
        {
            "count": 30,
            "city": "CHANDLER"
        },
        {
            "count": 9,
            "city": "EL MIRAGE"
        },
        {
            "count": 5,
            "city": "FOUNTAIN HILLS"
        },
        {
            "count": 32,
            "city": "GILBERT"
        },
        {
            "count": 52,
            "city": "GLENDALE"
        },
        {
            "count": 24,
            "city": "GOODYEAR"
        },
        {
            "count": 14,
            "city": "LAVEEN"
        },
        {
            "count": 1,
            "city": "MARICOPA"
        },
        {
            "count": 76,
            "city": "MESA"
        },
        {
            "count": 1,
            "city": "MORRISTOWN"
        },
        {
            "count": 1,
            "city": "NEW RIVER"
        },
        {
            "count": 39,
            "city": "PEORIA"
        },
        {
            "count": 196,
            "city": "PHOENIX"
        },
        {
            "count": 3,
            "city": "QUEEN CREEK"
        },
        {
            "count": 1,
            "city": "RIO VERDE"
        },
        {
            "count": 25,
            "city": "SCOTTSDALE"
        },
        {
            "count": 43,
            "city": "SUN CITY"
        },
        {
            "count": 14,
            "city": "SUN CITY WEST"
        },
        {
            "count": 4,
            "city": "SUN LAKES"
        },
        {
            "count": 30,
            "city": "SURPRISE"
        },
        {
            "count": 10,
            "city": "TEMPE"
        },
        {
            "count": 3,
            "city": "TOLLESON"
        },
        {
            "count": 1,
            "city": "TONOPAH"
        },
        {
            "count": 6,
            "city": "WITTMANN"
        },
        {
            "count": 2,
            "city": "YOUNGTOWN"
        }
    ],
    //-----------------------------------------------------------------------------------------------

    FL: [
        {
            "count": 2,
            "city": "ALACHUA"
        },
        {
            "count": 330,
            "city": "ALTAMONTE SPRINGS"
        },
        {
            "count": 47,
            "city": "ALTOONA"
        },
        {
            "count": 2,
            "city": "ALTURAS"
        },
        {
            "count": 1,
            "city": "ALVA"
        },
        {
            "count": 57,
            "city": "ANTHONY"
        },
        {
            "count": 104,
            "city": "APOLLO BEACH"
        },
        {
            "count": 798,
            "city": "APOPKA"
        },
        {
            "count": 45,
            "city": "ASTATULA"
        },
        {
            "count": 24,
            "city": "ASTOR"
        },
        {
            "count": 35,
            "city": "ATLANTIC BEACH"
        },
        {
            "count": 1,
            "city": "ATLANTIS"
        },
        {
            "count": 394,
            "city": "AUBURNDALE"
        },
        {
            "count": 1,
            "city": "AVE MARIA"
        },
        {
            "count": 40,
            "city": "AVENTURA"
        },
        {
            "count": 9,
            "city": "AVON PARK"
        },
        {
            "count": 1,
            "city": "AZALEA PARK"
        },
        {
            "count": 41,
            "city": "BABSON PARK"
        },
        {
            "count": 5,
            "city": "BALDWIN"
        },
        {
            "count": 240,
            "city": "BARTOW"
        },
        {
            "count": 8,
            "city": "BAY HARBOR ISLANDS"
        },
        {
            "count": 1,
            "city": "BAYONET POINT"
        },
        {
            "count": 130,
            "city": "BELLE GLADE"
        },
        {
            "count": 22,
            "city": "BELLE ISLE"
        },
        {
            "count": 9,
            "city": "BELLEAIR"
        },
        {
            "count": 2,
            "city": "BELLEAIR BEACH"
        },
        {
            "count": 254,
            "city": "BELLEVIEW"
        },
        {
            "count": 1,
            "city": "BIG PINE"
        },
        {
            "count": 13,
            "city": "BIG PINE KEY"
        },
        {
            "count": 4,
            "city": "BISCAYNE PARK"
        },
        {
            "count": 1,
            "city": "BLOUNTSTOWN"
        },
        {
            "count": 2325,
            "city": "BOCA RATON"
        },
        {
            "count": 1,
            "city": "BOWLING GREEN"
        },
        {
            "count": 1,
            "city": "BOYNTON"
        },
        {
            "count": 3984,
            "city": "BOYNTON BEACH"
        },
        {
            "count": 6,
            "city": "BRADENTON"
        },
        {
            "count": 554,
            "city": "BRANDON"
        },
        {
            "count": 3,
            "city": "BROOKSVILLE"
        },
        {
            "count": 1,
            "city": "BUNNELL"
        },
        {
            "count": 19,
            "city": "BUSHNELL"
        },
        {
            "count": 2,
            "city": "CALLAHAN"
        },
        {
            "count": 5,
            "city": "CANAL POINT"
        },
        {
            "count": 2,
            "city": "CANTONMENT"
        },
        {
            "count": 29,
            "city": "CAPE CANAVERAL"
        },
        {
            "count": 24,
            "city": "CAPE CORAL"
        },
        {
            "count": 323,
            "city": "CASSELBERRY"
        },
        {
            "count": 8,
            "city": "CELEBRATION"
        },
        {
            "count": 4,
            "city": "CENTER HILL"
        },
        {
            "count": 3,
            "city": "CHAMPIONS GATE"
        },
        {
            "count": 2,
            "city": "CHIEFLAND"
        },
        {
            "count": 2,
            "city": "CHIPLEY"
        },
        {
            "count": 39,
            "city": "CHRISTMAS"
        },
        {
            "count": 24,
            "city": "CHULUOTA"
        },
        {
            "count": 131,
            "city": "CITRA"
        },
        {
            "count": 2,
            "city": "CITRUS SPRINGS"
        },
        {
            "count": 422,
            "city": "CLEARWATER"
        },
        {
            "count": 1,
            "city": "CLEARWATER BEACH"
        },
        {
            "count": 1133,
            "city": "CLERMONT"
        },
        {
            "count": 53,
            "city": "CLEWISTON"
        },
        {
            "count": 1,
            "city": "CLOUD LAKE"
        },
        {
            "count": 737,
            "city": "COCOA"
        },
        {
            "count": 36,
            "city": "COCOA BEACH"
        },
        {
            "count": 795,
            "city": "COCONUT CREEK"
        },
        {
            "count": 3,
            "city": "COCONUT GROVE"
        },
        {
            "count": 2,
            "city": "COLEMAN"
        },
        {
            "count": 437,
            "city": "COOPER CITY"
        },
        {
            "count": 23,
            "city": "CORAL GABLES"
        },
        {
            "count": 2039,
            "city": "CORAL SPRINGS"
        },
        {
            "count": 1,
            "city": "CRAWFORDVILLE"
        },
        {
            "count": 4,
            "city": "CRESTVIEW"
        },
        {
            "count": 3,
            "city": "CRYSTAL BEACH"
        },
        {
            "count": 2,
            "city": "CRYSTAL RIVER"
        },
        {
            "count": 1,
            "city": "CUDJOE KEY"
        },
        {
            "count": 50,
            "city": "CUTLER BAY"
        },
        {
            "count": 54,
            "city": "DADE CITY"
        },
        {
            "count": 1,
            "city": "DADECNTY"
        },
        {
            "count": 12,
            "city": "DANIA"
        },
        {
            "count": 142,
            "city": "DANIA BEACH"
        },
        {
            "count": 1187,
            "city": "DAVENPORT"
        },
        {
            "count": 1252,
            "city": "DAVIE"
        },
        {
            "count": 234,
            "city": "DAYTONA BEACH"
        },
        {
            "count": 4,
            "city": "DAYTONA BEACH SHORES"
        },
        {
            "count": 20,
            "city": "DE LEON SPRINGS"
        },
        {
            "count": 83,
            "city": "DEBARY"
        },
        {
            "count": 1,
            "city": "DEER ISLAND"
        },
        {
            "count": 866,
            "city": "DEERFIELD BEACH"
        },
        {
            "count": 201,
            "city": "DELAND"
        },
        {
            "count": 1636,
            "city": "DELRAY BEACH"
        },
        {
            "count": 460,
            "city": "DELTONA"
        },
        {
            "count": 86,
            "city": "DORAL"
        },
        {
            "count": 79,
            "city": "DOVER"
        },
        {
            "count": 1,
            "city": "DRIVE VALRICO"
        },
        {
            "count": 106,
            "city": "DUNDEE"
        },
        {
            "count": 73,
            "city": "DUNEDIN"
        },
        {
            "count": 425,
            "city": "DUNNELLON"
        },
        {
            "count": 53,
            "city": "EAGLE LAKE"
        },
        {
            "count": 2,
            "city": "EATON PARK"
        },
        {
            "count": 6,
            "city": "EATONVILLE"
        },
        {
            "count": 76,
            "city": "EDGEWATER"
        },
        {
            "count": 2,
            "city": "EDGEWOOD"
        },
        {
            "count": 1,
            "city": "EL PORTAL"
        },
        {
            "count": 342,
            "city": "EUSTIS"
        },
        {
            "count": 103,
            "city": "FELLSMERE"
        },
        {
            "count": 23,
            "city": "FERN PARK"
        },
        {
            "count": 1,
            "city": "FERNANDINA BEACH"
        },
        {
            "count": 1,
            "city": "FLAGLER BEACH"
        },
        {
            "count": 51,
            "city": "FLEMING ISLAND"
        },
        {
            "count": 22,
            "city": "FLORIDA CITY"
        },
        {
            "count": 3197,
            "city": "FORT LAUDERDALE"
        },
        {
            "count": 124,
            "city": "FORT MC COY"
        },
        {
            "count": 1,
            "city": "FORT MCCOY"
        },
        {
            "count": 61,
            "city": "FORT MEADE"
        },
        {
            "count": 16,
            "city": "FORT MYERS"
        },
        {
            "count": 2115,
            "city": "FORT PIERCE"
        },
        {
            "count": 2,
            "city": "FORT WALTON BEACH"
        },
        {
            "count": 1,
            "city": "FREEPORT"
        },
        {
            "count": 105,
            "city": "FROSTPROOF"
        },
        {
            "count": 158,
            "city": "FRUITLAND PARK"
        },
        {
            "count": 8,
            "city": "FT LAUDERDALE"
        },
        {
            "count": 12,
            "city": "GAINESVILLE"
        },
        {
            "count": 53,
            "city": "GENEVA"
        },
        {
            "count": 128,
            "city": "GIBSONTON"
        },
        {
            "count": 1,
            "city": "GOLDEN BEACH"
        },
        {
            "count": 29,
            "city": "GOTHA"
        },
        {
            "count": 58,
            "city": "GRAND ISLAND"
        },
        {
            "count": 11,
            "city": "GRANT"
        },
        {
            "count": 1,
            "city": "GREEN COVE SPR"
        },
        {
            "count": 138,
            "city": "GREEN COVE SPRINGS"
        },
        {
            "count": 202,
            "city": "GREENACRES"
        },
        {
            "count": 324,
            "city": "GROVELAND"
        },
        {
            "count": 1,
            "city": "GULF BREEZE"
        },
        {
            "count": 1,
            "city": "GULF STREAM"
        },
        {
            "count": 42,
            "city": "GULFPORT"
        },
        {
            "count": 758,
            "city": "HAINES CITY"
        },
        {
            "count": 20,
            "city": "HALLANDALE"
        },
        {
            "count": 300,
            "city": "HALLANDALE BEACH"
        },
        {
            "count": 1,
            "city": "HASTINGS"
        },
        {
            "count": 38,
            "city": "HAVERHILL"
        },
        {
            "count": 1,
            "city": "HEATHROW"
        },
        {
            "count": 4844,
            "city": "HIALEAH"
        },
        {
            "count": 10,
            "city": "HIALEAH GARDENS"
        },
        {
            "count": 2,
            "city": "HIGH SPRINGS"
        },
        {
            "count": 2,
            "city": "HIGHLAND BEACH"
        },
        {
            "count": 4,
            "city": "HIGHLAND CITY"
        },
        {
            "count": 3,
            "city": "HILLSBORO BEACH"
        },
        {
            "count": 288,
            "city": "HOBE SOUND"
        },
        {
            "count": 230,
            "city": "HOLIDAY"
        },
        {
            "count": 25,
            "city": "HOLLY HILL"
        },
        {
            "count": 3140,
            "city": "HOLLYWOOD"
        },
        {
            "count": 1,
            "city": "HOMELAND"
        },
        {
            "count": 3495,
            "city": "HOMESTEAD"
        },
        {
            "count": 3,
            "city": "HOMESTED"
        },
        {
            "count": 3,
            "city": "HOMOSASSA"
        },
        {
            "count": 27,
            "city": "HOWEY IN THE HILLS"
        },
        {
            "count": 207,
            "city": "HUDSON"
        },
        {
            "count": 12,
            "city": "HUTCHINSON ISLAND"
        },
        {
            "count": 4,
            "city": "HYPOLUXO"
        },
        {
            "count": 1,
            "city": "IMMOKALEE"
        },
        {
            "count": 21,
            "city": "INDIALANTIC"
        },
        {
            "count": 3,
            "city": "INDIAN HARBOUR BEACH"
        },
        {
            "count": 26,
            "city": "INDIAN LAKE ESTATES"
        },
        {
            "count": 3,
            "city": "INDIAN ROCKS BEACH"
        },
        {
            "count": 92,
            "city": "INDIANTOWN"
        },
        {
            "count": 18,
            "city": "INTERCESSION CITY"
        },
        {
            "count": 7,
            "city": "INVERNESS"
        },
        {
            "count": 1,
            "city": "ISLAMORADA"
        },
        {
            "count": 5842,
            "city": "JACKSONVILLE"
        },
        {
            "count": 61,
            "city": "JACKSONVILLE BEACH"
        },
        {
            "count": 1,
            "city": "JENNINGS"
        },
        {
            "count": 270,
            "city": "JENSEN BEACH"
        },
        {
            "count": 1,
            "city": "JUNO BEACH"
        },
        {
            "count": 1070,
            "city": "JUPITER"
        },
        {
            "count": 5,
            "city": "KATHLEEN"
        },
        {
            "count": 15,
            "city": "KENANSVILLE"
        },
        {
            "count": 9,
            "city": "KENNETH CITY"
        },
        {
            "count": 25,
            "city": "KEY BISCAYNE"
        },
        {
            "count": 44,
            "city": "KEY LARGO"
        },
        {
            "count": 51,
            "city": "KEY WEST"
        },
        {
            "count": 70,
            "city": "KEYSTONE HEIGHTS"
        },
        {
            "count": 4384,
            "city": "KISSIMMEE"
        },
        {
            "count": 23,
            "city": "LABELLE"
        },
        {
            "count": 309,
            "city": "LADY LAKE"
        },
        {
            "count": 134,
            "city": "LAKE ALFRED"
        },
        {
            "count": 6,
            "city": "LAKE CITY"
        },
        {
            "count": 3,
            "city": "LAKE CLARKE SHORES"
        },
        {
            "count": 8,
            "city": "LAKE HAMILTON"
        },
        {
            "count": 10,
            "city": "LAKE HELEN"
        },
        {
            "count": 216,
            "city": "LAKE MARY"
        },
        {
            "count": 22,
            "city": "LAKE PANASOFFKEE"
        },
        {
            "count": 114,
            "city": "LAKE PARK"
        },
        {
            "count": 9,
            "city": "LAKE PLACID"
        },
        {
            "count": 719,
            "city": "LAKE WALES"
        },
        {
            "count": 3409,
            "city": "LAKE WORTH"
        },
        {
            "count": 13,
            "city": "LAKE WORTH BEACH"
        },
        {
            "count": 2367,
            "city": "LAKELAND"
        },
        {
            "count": 1,
            "city": "LAKESHORE"
        },
        {
            "count": 126,
            "city": "LAND O LAKES"
        },
        {
            "count": 27,
            "city": "LANTANA"
        },
        {
            "count": 189,
            "city": "LARGO"
        },
        {
            "count": 1,
            "city": "LAUD LAKES"
        },
        {
            "count": 11,
            "city": "LAUDERDALE BY THE SEA"
        },
        {
            "count": 713,
            "city": "LAUDERDALE LAKES"
        },
        {
            "count": 1630,
            "city": "LAUDERHILL"
        },
        {
            "count": 1,
            "city": "LAWTEY"
        },
        {
            "count": 1,
            "city": "LECANTO"
        },
        {
            "count": 958,
            "city": "LEESBURG"
        },
        {
            "count": 25,
            "city": "LEHIGH ACRES"
        },
        {
            "count": 3,
            "city": "LIGHTHOUSE POINT"
        },
        {
            "count": 115,
            "city": "LIGHTHOUSE PT"
        },
        {
            "count": 81,
            "city": "LITHIA"
        },
        {
            "count": 1,
            "city": "LOCKHART"
        },
        {
            "count": 336,
            "city": "LONGWOOD"
        },
        {
            "count": 6,
            "city": "LORIDA"
        },
        {
            "count": 741,
            "city": "LOXAHATCHEE"
        },
        {
            "count": 1,
            "city": "LOXAHATCHEE GROVES"
        },
        {
            "count": 190,
            "city": "LUTZ"
        },
        {
            "count": 1,
            "city": "MACCLENNY"
        },
        {
            "count": 4,
            "city": "MADEIRA BEACH"
        },
        {
            "count": 78,
            "city": "MAITLAND"
        },
        {
            "count": 27,
            "city": "MALABAR"
        },
        {
            "count": 10,
            "city": "MANGONIA PARK"
        },
        {
            "count": 11,
            "city": "MARATHON"
        },
        {
            "count": 1467,
            "city": "MARGATE"
        },
        {
            "count": 1,
            "city": "MARY ESTHER"
        },
        {
            "count": 97,
            "city": "MASCOTTE"
        },
        {
            "count": 1,
            "city": "MCINTOSH"
        },
        {
            "count": 11,
            "city": "MEDLEY"
        },
        {
            "count": 704,
            "city": "MELBOURNE"
        },
        {
            "count": 31,
            "city": "MELBOURNE BEACH"
        },
        {
            "count": 5,
            "city": "MELROSE"
        },
        {
            "count": 1,
            "city": "MERRITT ISL"
        },
        {
            "count": 231,
            "city": "MERRITT ISLAND"
        },
        {
            "count": 23908,
            "city": "MIAMI"
        },
        {
            "count": 264,
            "city": "MIAMI BEACH"
        },
        {
            "count": 1523,
            "city": "MIAMI GARDENS"
        },
        {
            "count": 21,
            "city": "MIAMI LAKES"
        },
        {
            "count": 4,
            "city": "MIAMI SHORES"
        },
        {
            "count": 4,
            "city": "MIAMI SPRINGS"
        },
        {
            "count": 1,
            "city": "MIAMIGAR"
        },
        {
            "count": 14,
            "city": "MICANOPY"
        },
        {
            "count": 1,
            "city": "MICCO"
        },
        {
            "count": 292,
            "city": "MIDDLEBURG"
        },
        {
            "count": 2,
            "city": "MIDWAY"
        },
        {
            "count": 4,
            "city": "MILTON"
        },
        {
            "count": 154,
            "city": "MIMS"
        },
        {
            "count": 121,
            "city": "MINNEOLA"
        },
        {
            "count": 3421,
            "city": "MIRAMAR"
        },
        {
            "count": 1,
            "city": "MONTICELLO"
        },
        {
            "count": 47,
            "city": "MONTVERDE"
        },
        {
            "count": 57,
            "city": "MOORE HAVEN"
        },
        {
            "count": 9,
            "city": "MORRISTON"
        },
        {
            "count": 269,
            "city": "MOUNT DORA"
        },
        {
            "count": 3,
            "city": "MOUNT PLYMOUTH"
        },
        {
            "count": 224,
            "city": "MULBERRY"
        },
        {
            "count": 20,
            "city": "NAPLES"
        },
        {
            "count": 3,
            "city": "NARANJA"
        },
        {
            "count": 1,
            "city": "NAVARRE"
        },
        {
            "count": 13,
            "city": "NEPTUNE BEACH"
        },
        {
            "count": 404,
            "city": "NEW PORT RICHEY"
        },
        {
            "count": 2,
            "city": "NEW PRT RCHY"
        },
        {
            "count": 35,
            "city": "NEW PT RICHEY"
        },
        {
            "count": 74,
            "city": "NEW SMYRNA BEACH"
        },
        {
            "count": 1,
            "city": "NEWBERRY"
        },
        {
            "count": 1,
            "city": "NMIAMIBC"
        },
        {
            "count": 7,
            "city": "NORTH BAY VILLAGE"
        },
        {
            "count": 2,
            "city": "NORTH FORT MYERS"
        },
        {
            "count": 1020,
            "city": "NORTH LAUDERDALE"
        },
        {
            "count": 35,
            "city": "NORTH MIAMI"
        },
        {
            "count": 95,
            "city": "NORTH MIAMI BEACH"
        },
        {
            "count": 123,
            "city": "NORTH PALM BEACH"
        },
        {
            "count": 6,
            "city": "NORTH PORT"
        },
        {
            "count": 1,
            "city": "NORTH REDINGTON BEACH"
        },
        {
            "count": 1,
            "city": "O'BRIEN"
        },
        {
            "count": 19,
            "city": "OAK HILL"
        },
        {
            "count": 12,
            "city": "OAKLAND"
        },
        {
            "count": 464,
            "city": "OAKLAND PARK"
        },
        {
            "count": 3592,
            "city": "OCALA"
        },
        {
            "count": 3,
            "city": "OCEAN RIDGE"
        },
        {
            "count": 189,
            "city": "OCKLAWAHA"
        },
        {
            "count": 374,
            "city": "OCOEE"
        },
        {
            "count": 63,
            "city": "ODESSA"
        },
        {
            "count": 16,
            "city": "OKAHUMPKA"
        },
        {
            "count": 956,
            "city": "OKEECHOBEE"
        },
        {
            "count": 59,
            "city": "OLDSMAR"
        },
        {
            "count": 1,
            "city": "ONA"
        },
        {
            "count": 997,
            "city": "OPA LOCKA"
        },
        {
            "count": 71,
            "city": "ORANGE CITY"
        },
        {
            "count": 286,
            "city": "ORANGE PARK"
        },
        {
            "count": 8711,
            "city": "ORLANDO"
        },
        {
            "count": 141,
            "city": "ORMOND BEACH"
        },
        {
            "count": 19,
            "city": "OSTEEN"
        },
        {
            "count": 317,
            "city": "OVIEDO"
        },
        {
            "count": 19,
            "city": "OXFORD"
        },
        {
            "count": 1,
            "city": "PACE"
        },
        {
            "count": 56,
            "city": "PAHOKEE"
        },
        {
            "count": 56,
            "city": "PAISLEY"
        },
        {
            "count": 1272,
            "city": "PALM BAY"
        },
        {
            "count": 29,
            "city": "PALM BEACH"
        },
        {
            "count": 586,
            "city": "PALM BEACH GARDENS"
        },
        {
            "count": 298,
            "city": "PALM CITY"
        },
        {
            "count": 24,
            "city": "PALM COAST"
        },
        {
            "count": 142,
            "city": "PALM HARBOR"
        },
        {
            "count": 42,
            "city": "PALM SPRINGS"
        },
        {
            "count": 1,
            "city": "PALMDALE"
        },
        {
            "count": 2,
            "city": "PALMETTO"
        },
        {
            "count": 10,
            "city": "PALMETTO BAY"
        },
        {
            "count": 2,
            "city": "PANAMA CITY BEACH"
        },
        {
            "count": 294,
            "city": "PARKLAND"
        },
        {
            "count": 10,
            "city": "PEMBROKE PARK"
        },
        {
            "count": 2452,
            "city": "PEMBROKE PINES"
        },
        {
            "count": 14,
            "city": "PENSACOLA"
        },
        {
            "count": 10,
            "city": "PIERSON"
        },
        {
            "count": 2,
            "city": "PINECREST"
        },
        {
            "count": 140,
            "city": "PINELLAS PARK"
        },
        {
            "count": 488,
            "city": "PLANT CITY"
        },
        {
            "count": 1420,
            "city": "PLANTATION"
        },
        {
            "count": 126,
            "city": "POINCIANA"
        },
        {
            "count": 72,
            "city": "POLK CITY"
        },
        {
            "count": 1737,
            "city": "POMPANO BEACH"
        },
        {
            "count": 1,
            "city": "PONCE INLET"
        },
        {
            "count": 4,
            "city": "PONTE VEDRA"
        },
        {
            "count": 33,
            "city": "PORT ORANGE"
        },
        {
            "count": 278,
            "city": "PORT RICHEY"
        },
        {
            "count": 4837,
            "city": "PORT SAINT LUCIE"
        },
        {
            "count": 1,
            "city": "PORT ST JOHN"
        },
        {
            "count": 41,
            "city": "PORT ST LUCIE"
        },
        {
            "count": 1,
            "city": "PRINCETON"
        },
        {
            "count": 2,
            "city": "PT CHARLOTTE"
        },
        {
            "count": 1,
            "city": "PT ORANGE"
        },
        {
            "count": 3,
            "city": "PT RICHEY"
        },
        {
            "count": 63,
            "city": "PT SAINT LUCIE"
        },
        {
            "count": 1,
            "city": "PT ST LUCIE"
        },
        {
            "count": 2,
            "city": "PUNTA GORDA"
        },
        {
            "count": 72,
            "city": "REDDICK"
        },
        {
            "count": 1,
            "city": "REDINGTON BEACH"
        },
        {
            "count": 1,
            "city": "REDINGTON SHORES"
        },
        {
            "count": 4,
            "city": "REUNION"
        },
        {
            "count": 1,
            "city": "RIVER RANCH"
        },
        {
            "count": 696,
            "city": "RIVERVIEW"
        },
        {
            "count": 709,
            "city": "RIVIERA BEACH"
        },
        {
            "count": 242,
            "city": "ROCKLEDGE"
        },
        {
            "count": 1,
            "city": "ROTONDA WEST"
        },
        {
            "count": 499,
            "city": "ROYAL PALM BEACH"
        },
        {
            "count": 206,
            "city": "RUSKIN"
        },
        {
            "count": 35,
            "city": "SAFETY HARBOR"
        },
        {
            "count": 3,
            "city": "SAINT AUGUSTINE"
        },
        {
            "count": 1128,
            "city": "SAINT CLOUD"
        },
        {
            "count": 5,
            "city": "SAINT JOHNS"
        },
        {
            "count": 2,
            "city": "SAINT LUCIE WEST"
        },
        {
            "count": 5,
            "city": "SAINT PETE BEACH"
        },
        {
            "count": 595,
            "city": "SAINT PETERSBURG"
        },
        {
            "count": 22,
            "city": "SALT SPRINGS"
        },
        {
            "count": 22,
            "city": "SAN ANTONIO"
        },
        {
            "count": 772,
            "city": "SANFORD"
        },
        {
            "count": 1,
            "city": "SANTA ROSA BEACH"
        },
        {
            "count": 7,
            "city": "SARASOTA"
        },
        {
            "count": 65,
            "city": "SATELLITE BEACH"
        },
        {
            "count": 548,
            "city": "SEBASTIAN"
        },
        {
            "count": 18,
            "city": "SEBRING"
        },
        {
            "count": 240,
            "city": "SEFFNER"
        },
        {
            "count": 72,
            "city": "SEMINOLE"
        },
        {
            "count": 3,
            "city": "SEVILLE"
        },
        {
            "count": 1,
            "city": "SHALIMAR"
        },
        {
            "count": 196,
            "city": "SILVER SPRINGS"
        },
        {
            "count": 59,
            "city": "SORRENTO"
        },
        {
            "count": 23,
            "city": "SOUTH BAY"
        },
        {
            "count": 17,
            "city": "SOUTH DAYTONA"
        },
        {
            "count": 5,
            "city": "SOUTH MIAMI"
        },
        {
            "count": 1,
            "city": "SOUTH PALM BEACH"
        },
        {
            "count": 8,
            "city": "SOUTH PASADENA"
        },
        {
            "count": 79,
            "city": "SOUTHWEST RANCHES"
        },
        {
            "count": 41,
            "city": "SPRING HILL"
        },
        {
            "count": 1,
            "city": "ST CLOUD"
        },
        {
            "count": 1,
            "city": "ST LUCIE"
        },
        {
            "count": 1,
            "city": "ST LUCIE WEST"
        },
        {
            "count": 19,
            "city": "ST PETERSBURG"
        },
        {
            "count": 3,
            "city": "STARKE"
        },
        {
            "count": 862,
            "city": "STUART"
        },
        {
            "count": 631,
            "city": "SUMMERFIELD"
        },
        {
            "count": 3,
            "city": "SUMMERLAND KEY"
        },
        {
            "count": 1,
            "city": "SUMTERVILLE"
        },
        {
            "count": 1,
            "city": "SUN CITY"
        },
        {
            "count": 225,
            "city": "SUN CITY CENTER"
        },
        {
            "count": 1,
            "city": "SUNDANCE"
        },
        {
            "count": 8,
            "city": "SUNNY ISLES BEACH"
        },
        {
            "count": 1951,
            "city": "SUNRISE"
        },
        {
            "count": 4,
            "city": "SURFSIDE"
        },
        {
            "count": 9,
            "city": "TALLAHASSEE"
        },
        {
            "count": 1992,
            "city": "TAMARAC"
        },
        {
            "count": 4925,
            "city": "TAMPA"
        },
        {
            "count": 87,
            "city": "TARPON SPRINGS"
        },
        {
            "count": 267,
            "city": "TAVARES"
        },
        {
            "count": 9,
            "city": "TAVERNIER"
        },
        {
            "count": 59,
            "city": "TEMPLE TERRACE"
        },
        {
            "count": 69,
            "city": "TEQUESTA"
        },
        {
            "count": 1,
            "city": "TERRACE"
        },
        {
            "count": 1,
            "city": "THE ACREAGE"
        },
        {
            "count": 287,
            "city": "THE VILLAGES"
        },
        {
            "count": 56,
            "city": "THONOTOSASSA"
        },
        {
            "count": 1,
            "city": "TIERRA VERDE"
        },
        {
            "count": 637,
            "city": "TITUSVILLE"
        },
        {
            "count": 8,
            "city": "TREASURE ISLAND"
        },
        {
            "count": 1,
            "city": "TRENTON"
        },
        {
            "count": 4,
            "city": "TRINITY"
        },
        {
            "count": 135,
            "city": "UMATILLA"
        },
        {
            "count": 386,
            "city": "VALRICO"
        },
        {
            "count": 3,
            "city": "VENICE"
        },
        {
            "count": 1,
            "city": "VENUS"
        },
        {
            "count": 1490,
            "city": "VERO BEACH"
        },
        {
            "count": 1,
            "city": "VIERA"
        },
        {
            "count": 1,
            "city": "VILLAGE OF PALMETTO BAY"
        },
        {
            "count": 1,
            "city": "VIRGINIA GARDENS"
        },
        {
            "count": 9,
            "city": "WAVERLY"
        },
        {
            "count": 20,
            "city": "WEBSTER"
        },
        {
            "count": 2,
            "city": "WEEKI WACHEE"
        },
        {
            "count": 49,
            "city": "WEIRSDALE"
        },
        {
            "count": 993,
            "city": "WELLINGTON"
        },
        {
            "count": 1,
            "city": "WESCHESTER"
        },
        {
            "count": 71,
            "city": "WESLEY CHAPEL"
        },
        {
            "count": 23,
            "city": "WEST MELBOURNE"
        },
        {
            "count": 1,
            "city": "WEST MIAMI"
        },
        {
            "count": 1,
            "city": "WEST PALM BCH"
        },
        {
            "count": 3759,
            "city": "WEST PALM BEACH"
        },
        {
            "count": 618,
            "city": "WEST PARK"
        },
        {
            "count": 2,
            "city": "WESTLAKE"
        },
        {
            "count": 668,
            "city": "WESTON"
        },
        {
            "count": 88,
            "city": "WILDWOOD"
        },
        {
            "count": 13,
            "city": "WILLISTON"
        },
        {
            "count": 139,
            "city": "WILTON MANORS"
        },
        {
            "count": 148,
            "city": "WIMAUMA"
        },
        {
            "count": 139,
            "city": "WINDERMERE"
        },
        {
            "count": 388,
            "city": "WINTER GARDEN"
        },
        {
            "count": 1670,
            "city": "WINTER HAVEN"
        },
        {
            "count": 287,
            "city": "WINTER PARK"
        },
        {
            "count": 283,
            "city": "WINTER SPRINGS"
        },
        {
            "count": 19,
            "city": "YALAHA"
        },
        {
            "count": 1,
            "city": "YOUNGSTOWN"
        },
        {
            "count": 1,
            "city": "YULEE"
        },
        {
            "count": 46,
            "city": "ZELLWOOD"
        },
        {
            "count": 196,
            "city": "ZEPHYRHILLS"
        }
    ],
    //-----------------------------------------------------------------------------------------------------------------
    OH: [
        {
            "count": 2,
            "city": "AKRON"
        },
        {
            "count": 6,
            "city": "ALLIANCE"
        },
        {
            "count": 23,
            "city": "AMHERST"
        },
        {
            "count": 2,
            "city": "ATTICA"
        },
        {
            "count": 4,
            "city": "ATWATER"
        },
        {
            "count": 7,
            "city": "AURORA"
        },
        {
            "count": 4,
            "city": "AVON"
        },
        {
            "count": 9,
            "city": "AVON LAKE"
        },
        {
            "count": 1,
            "city": "BATAVIA"
        },
        {
            "count": 4,
            "city": "BAY VILLAGE"
        },
        {
            "count": 1,
            "city": "BEACH CITY"
        },
        {
            "count": 7,
            "city": "BEDFORD"
        },
        {
            "count": 1,
            "city": "BEDFORD HEIGHTS"
        },
        {
            "count": 18,
            "city": "BELLEVUE"
        },
        {
            "count": 6,
            "city": "BEREA"
        },
        {
            "count": 2,
            "city": "BERLIN HEIGHTS"
        },
        {
            "count": 1,
            "city": "BRECKSVILLE"
        },
        {
            "count": 1,
            "city": "BROADVIEW HEIGHTS"
        },
        {
            "count": 7,
            "city": "BROOKLYN"
        },
        {
            "count": 7,
            "city": "BROOKPARK"
        },
        {
            "count": 19,
            "city": "BRUNSWICK"
        },
        {
            "count": 1,
            "city": "BURGOON"
        },
        {
            "count": 3,
            "city": "BURTON"
        },
        {
            "count": 5,
            "city": "CANAL FULTON"
        },
        {
            "count": 55,
            "city": "CANTON"
        },
        {
            "count": 5,
            "city": "CASTALIA"
        },
        {
            "count": 6,
            "city": "CHAGRIN FALLS"
        },
        {
            "count": 4,
            "city": "CHARDON"
        },
        {
            "count": 6,
            "city": "CHESTERLAND"
        },
        {
            "count": 1,
            "city": "CHIPPEWA LAKE"
        },
        {
            "count": 244,
            "city": "CLEVELAND"
        },
        {
            "count": 11,
            "city": "CLEVELAND HEIGHTS"
        },
        {
            "count": 4,
            "city": "CLYDE"
        },
        {
            "count": 3,
            "city": "COLLINS"
        },
        {
            "count": 1,
            "city": "COLUMBIA STATION"
        },
        {
            "count": 1,
            "city": "CURTICE"
        },
        {
            "count": 3,
            "city": "DEERFIELD"
        },
        {
            "count": 1,
            "city": "DELPHOS"
        },
        {
            "count": 1,
            "city": "DIAMOND"
        },
        {
            "count": 2,
            "city": "EAST CANTON"
        },
        {
            "count": 4,
            "city": "EAST CLEVELAND"
        },
        {
            "count": 1,
            "city": "EAST SPARTA"
        },
        {
            "count": 19,
            "city": "EASTLAKE"
        },
        {
            "count": 2,
            "city": "ELMORE"
        },
        {
            "count": 75,
            "city": "ELYRIA"
        },
        {
            "count": 28,
            "city": "EUCLID"
        },
        {
            "count": 1,
            "city": "FAIRLAWN"
        },
        {
            "count": 4,
            "city": "FAIRVIEW PARK"
        },
        {
            "count": 8,
            "city": "FOSTORIA"
        },
        {
            "count": 32,
            "city": "FREMONT"
        },
        {
            "count": 9,
            "city": "GARFIELD HEIGHTS"
        },
        {
            "count": 5,
            "city": "GARRETTSVILLE"
        },
        {
            "count": 4,
            "city": "GIBSONBURG"
        },
        {
            "count": 14,
            "city": "GRAFTON"
        },
        {
            "count": 1,
            "city": "GREEN SPRINGS"
        },
        {
            "count": 1,
            "city": "GREENWICH"
        },
        {
            "count": 3,
            "city": "HARTVILLE"
        },
        {
            "count": 1,
            "city": "HELENA"
        },
        {
            "count": 1,
            "city": "HIGHLAND HEIGHTS"
        },
        {
            "count": 1,
            "city": "HIGHLAND HILLS"
        },
        {
            "count": 1,
            "city": "HINCKLEY"
        },
        {
            "count": 2,
            "city": "HIRAM"
        },
        {
            "count": 1,
            "city": "HUDSON"
        },
        {
            "count": 1,
            "city": "HUNTSBURG"
        },
        {
            "count": 15,
            "city": "HURON"
        },
        {
            "count": 4,
            "city": "INDEPENDENCE"
        },
        {
            "count": 1,
            "city": "KELLEYS ISLAND"
        },
        {
            "count": 28,
            "city": "KENT"
        },
        {
            "count": 6,
            "city": "LAGRANGE"
        },
        {
            "count": 4,
            "city": "LAKESIDE MARBLEHEAD"
        },
        {
            "count": 9,
            "city": "LAKEWOOD"
        },
        {
            "count": 2,
            "city": "LINDSEY"
        },
        {
            "count": 1,
            "city": "LITCHFIELD"
        },
        {
            "count": 68,
            "city": "LORAIN"
        },
        {
            "count": 5,
            "city": "LOUISVILLE"
        },
        {
            "count": 7,
            "city": "LYNDHURST"
        },
        {
            "count": 16,
            "city": "MADISON"
        },
        {
            "count": 5,
            "city": "MANTUA"
        },
        {
            "count": 23,
            "city": "MAPLE HEIGHTS"
        },
        {
            "count": 1,
            "city": "MARION"
        },
        {
            "count": 14,
            "city": "MASSILLON"
        },
        {
            "count": 6,
            "city": "MAYFIELD HEIGHTS"
        },
        {
            "count": 1,
            "city": "MAYFIELD VILLAGE"
        },
        {
            "count": 11,
            "city": "MEDINA"
        },
        {
            "count": 30,
            "city": "MENTOR"
        },
        {
            "count": 3,
            "city": "MIDDLEBURG HEIGHTS"
        },
        {
            "count": 4,
            "city": "MIDDLEFIELD"
        },
        {
            "count": 1,
            "city": "MIDDLETOWN"
        },
        {
            "count": 4,
            "city": "MILAN"
        },
        {
            "count": 1,
            "city": "MINERVA"
        },
        {
            "count": 8,
            "city": "MOGADORE"
        },
        {
            "count": 2,
            "city": "MONROEVILLE"
        },
        {
            "count": 5,
            "city": "NAVARRE"
        },
        {
            "count": 9,
            "city": "NEW LONDON"
        },
        {
            "count": 1,
            "city": "NEWBURGH HEIGHTS"
        },
        {
            "count": 3,
            "city": "NEWBURY"
        },
        {
            "count": 5,
            "city": "NORTH CANTON"
        },
        {
            "count": 1,
            "city": "NORTH FAIRFIELD"
        },
        {
            "count": 20,
            "city": "NORTH OLMSTED"
        },
        {
            "count": 20,
            "city": "NORTH RIDGEVILLE"
        },
        {
            "count": 11,
            "city": "NORTH ROYALTON"
        },
        {
            "count": 40,
            "city": "NORWALK"
        },
        {
            "count": 1,
            "city": "NOVELTY"
        },
        {
            "count": 8,
            "city": "OAK HARBOR"
        },
        {
            "count": 11,
            "city": "OBERLIN"
        },
        {
            "count": 2,
            "city": "OLMSTED FALLS"
        },
        {
            "count": 4,
            "city": "OLMSTED TWP"
        },
        {
            "count": 23,
            "city": "PAINESVILLE"
        },
        {
            "count": 2,
            "city": "PARIS"
        },
        {
            "count": 30,
            "city": "PARMA"
        },
        {
            "count": 4,
            "city": "PARMA HEIGHTS"
        },
        {
            "count": 1,
            "city": "PEPPER PIKE"
        },
        {
            "count": 3,
            "city": "PERRY"
        },
        {
            "count": 1,
            "city": "PERRYSBURG"
        },
        {
            "count": 1,
            "city": "PLYMOUTH"
        },
        {
            "count": 1,
            "city": "PORT CLINTON"
        },
        {
            "count": 1,
            "city": "PT CLINTON"
        },
        {
            "count": 1,
            "city": "PUT IN BAY"
        },
        {
            "count": 31,
            "city": "RAVENNA"
        },
        {
            "count": 2,
            "city": "REPUBLIC"
        },
        {
            "count": 7,
            "city": "RICHMOND HEIGHTS"
        },
        {
            "count": 2,
            "city": "RISINGSUN"
        },
        {
            "count": 3,
            "city": "ROCKY RIVER"
        },
        {
            "count": 7,
            "city": "ROOTSTOWN"
        },
        {
            "count": 1,
            "city": "SAGAMORE HILLS"
        },
        {
            "count": 68,
            "city": "SANDUSKY"
        },
        {
            "count": 5,
            "city": "SEVEN HILLS"
        },
        {
            "count": 5,
            "city": "SHAKER HEIGHTS"
        },
        {
            "count": 7,
            "city": "SHEFFIELD LAKE"
        },
        {
            "count": 3,
            "city": "SHEFFIELD VILLAGE"
        },
        {
            "count": 7,
            "city": "SOLON"
        },
        {
            "count": 3,
            "city": "SOUTH AMHERST"
        },
        {
            "count": 8,
            "city": "SOUTH EUCLID"
        },
        {
            "count": 1,
            "city": "SPENCER"
        },
        {
            "count": 1,
            "city": "STOW"
        },
        {
            "count": 7,
            "city": "STREETSBORO"
        },
        {
            "count": 9,
            "city": "STRONGSVILLE"
        },
        {
            "count": 1,
            "city": "THOMPSON"
        },
        {
            "count": 13,
            "city": "TIFFIN"
        },
        {
            "count": 1,
            "city": "TOLEDO"
        },
        {
            "count": 8,
            "city": "UNIONTOWN"
        },
        {
            "count": 4,
            "city": "UNIVERSITY HEIGHTS"
        },
        {
            "count": 3,
            "city": "VALLEY CITY"
        },
        {
            "count": 2,
            "city": "VALLEY VIEW"
        },
        {
            "count": 17,
            "city": "VERMILION"
        },
        {
            "count": 6,
            "city": "WADSWORTH"
        },
        {
            "count": 15,
            "city": "WAKEMAN"
        },
        {
            "count": 1,
            "city": "WAYNESBURG"
        },
        {
            "count": 25,
            "city": "WELLINGTON"
        },
        {
            "count": 7,
            "city": "WESTLAKE"
        },
        {
            "count": 11,
            "city": "WICKLIFFE"
        },
        {
            "count": 6,
            "city": "WILLARD"
        },
        {
            "count": 7,
            "city": "WILLOUGHBY"
        },
        {
            "count": 1,
            "city": "WILLOUGHBY HILLS"
        },
        {
            "count": 8,
            "city": "WILLOWICK"
        },
        {
            "count": 4,
            "city": "WINDHAM"
        }
    ],
    //--------------------------------------------------------------------------------------------------------------------
    TX: [
        {
            "count": 33,
            "city": "ADKINS"
        },
        {
            "count": 25,
            "city": "ALICE"
        },
        {
            "count": 6,
            "city": "ANTHONY"
        },
        {
            "count": 34,
            "city": "ARANSAS PASS"
        },
        {
            "count": 43,
            "city": "ATASCOSA"
        },
        {
            "count": 1,
            "city": "AUSTIN"
        },
        {
            "count": 34,
            "city": "BANDERA"
        },
        {
            "count": 2,
            "city": "BEEVILLE"
        },
        {
            "count": 7,
            "city": "BISHOP"
        },
        {
            "count": 65,
            "city": "BOERNE"
        },
        {
            "count": 1,
            "city": "BOWIE"
        },
        {
            "count": 1,
            "city": "BROOKSHIRE"
        },
        {
            "count": 1,
            "city": "BUDA"
        },
        {
            "count": 8,
            "city": "BULVERDE"
        },
        {
            "count": 12,
            "city": "CANUTILLO"
        },
        {
            "count": 10,
            "city": "CANYON LAKE"
        },
        {
            "count": 4,
            "city": "CASTLE HILLS"
        },
        {
            "count": 31,
            "city": "CASTROVILLE"
        },
        {
            "count": 1,
            "city": "CEDAR PARK"
        },
        {
            "count": 3,
            "city": "CHINA GROVE"
        },
        {
            "count": 92,
            "city": "CIBOLO"
        },
        {
            "count": 6,
            "city": "CLINT"
        },
        {
            "count": 4,
            "city": "COMFORT"
        },
        {
            "count": 1,
            "city": "CONROE"
        },
        {
            "count": 323,
            "city": "CONVERSE"
        },
        {
            "count": 529,
            "city": "CORPUS CHRISTI"
        },
        {
            "count": 1,
            "city": "CROSBY"
        },
        {
            "count": 2,
            "city": "DALLAS"
        },
        {
            "count": 1,
            "city": "DEER PARK"
        },
        {
            "count": 1,
            "city": "DESOTO"
        },
        {
            "count": 28,
            "city": "DEVINE"
        },
        {
            "count": 1086,
            "city": "EL PASO"
        },
        {
            "count": 25,
            "city": "ELMENDORF"
        },
        {
            "count": 1,
            "city": "FABENS"
        },
        {
            "count": 2,
            "city": "FAIR OAKS RANCH"
        },
        {
            "count": 2,
            "city": "FISCHER"
        },
        {
            "count": 37,
            "city": "FLORESVILLE"
        },
        {
            "count": 74,
            "city": "HELOTES"
        },
        {
            "count": 30,
            "city": "HONDO"
        },
        {
            "count": 37,
            "city": "HORIZON CITY"
        },
        {
            "count": 11,
            "city": "HOUSTON"
        },
        {
            "count": 47,
            "city": "INGLESIDE"
        },
        {
            "count": 2,
            "city": "JOURDANTON"
        },
        {
            "count": 2,
            "city": "KATY"
        },
        {
            "count": 1,
            "city": "KENDALIA"
        },
        {
            "count": 1,
            "city": "KERRVILLE"
        },
        {
            "count": 1,
            "city": "KINGSBURY"
        },
        {
            "count": 1,
            "city": "KINGSLAND"
        },
        {
            "count": 53,
            "city": "KINGSVILLE"
        },
        {
            "count": 26,
            "city": "KIRBY"
        },
        {
            "count": 2,
            "city": "LA COSTE"
        },
        {
            "count": 14,
            "city": "LA VERNIA"
        },
        {
            "count": 1,
            "city": "LAKE CITY"
        },
        {
            "count": 6,
            "city": "LAKEHILLS"
        },
        {
            "count": 1,
            "city": "LAVERNIA"
        },
        {
            "count": 2,
            "city": "LEON VALLEY"
        },
        {
            "count": 137,
            "city": "LIVE OAK"
        },
        {
            "count": 1,
            "city": "LUBBOCK"
        },
        {
            "count": 5,
            "city": "LYTLE"
        },
        {
            "count": 17,
            "city": "MARION"
        },
        {
            "count": 16,
            "city": "MATHIS"
        },
        {
            "count": 4,
            "city": "MC QUEENEY"
        },
        {
            "count": 1,
            "city": "MEDINA"
        },
        {
            "count": 4,
            "city": "MICO"
        },
        {
            "count": 1,
            "city": "MIDLAND"
        },
        {
            "count": 1,
            "city": "MOORE"
        },
        {
            "count": 9,
            "city": "NATALIA"
        },
        {
            "count": 142,
            "city": "NEW BRAUNFELS"
        },
        {
            "count": 6,
            "city": "ODEM"
        },
        {
            "count": 3,
            "city": "ORANGE GROVE"
        },
        {
            "count": 1,
            "city": "PAMPA"
        },
        {
            "count": 1,
            "city": "PASADENA"
        },
        {
            "count": 18,
            "city": "PIPE CREEK"
        },
        {
            "count": 1,
            "city": "PLANO"
        },
        {
            "count": 6,
            "city": "PLEASANTON"
        },
        {
            "count": 7,
            "city": "PORT ARANSAS"
        },
        {
            "count": 61,
            "city": "PORTLAND"
        },
        {
            "count": 8,
            "city": "POTEET"
        },
        {
            "count": 1,
            "city": "POTH"
        },
        {
            "count": 1,
            "city": "PT ARANSAS"
        },
        {
            "count": 4,
            "city": "RICHMOND"
        },
        {
            "count": 1,
            "city": "RIO MEDINA"
        },
        {
            "count": 1,
            "city": "RIVIERA"
        },
        {
            "count": 21,
            "city": "ROBSTOWN"
        },
        {
            "count": 35,
            "city": "ROCKPORT"
        },
        {
            "count": 1,
            "city": "ROUND ROCK"
        },
        {
            "count": 11,
            "city": "SAINT HEDWIG"
        },
        {
            "count": 9029,
            "city": "SAN ANTONIO"
        },
        {
            "count": 3,
            "city": "SAN ELIZARIO"
        },
        {
            "count": 3,
            "city": "SAN MARCOS"
        },
        {
            "count": 3,
            "city": "SANDIA"
        },
        {
            "count": 89,
            "city": "SCHERTZ"
        },
        {
            "count": 44,
            "city": "SEGUIN"
        },
        {
            "count": 23,
            "city": "SELMA"
        },
        {
            "count": 2,
            "city": "SHAVANO PARK"
        },
        {
            "count": 13,
            "city": "SINTON"
        },
        {
            "count": 28,
            "city": "SOCORRO"
        },
        {
            "count": 11,
            "city": "SOMERSET"
        },
        {
            "count": 1,
            "city": "SPRING"
        },
        {
            "count": 11,
            "city": "SPRING BRANCH"
        },
        {
            "count": 3,
            "city": "STOCKDALE"
        },
        {
            "count": 1,
            "city": "SUTHERLAND SPRINGS"
        },
        {
            "count": 9,
            "city": "TAFT"
        },
        {
            "count": 1,
            "city": "TAYLOR"
        },
        {
            "count": 1,
            "city": "TOMBALL"
        },
        {
            "count": 125,
            "city": "UNIVERSAL CITY"
        },
        {
            "count": 2,
            "city": "VICTORIA"
        },
        {
            "count": 2,
            "city": "VINTON"
        },
        {
            "count": 27,
            "city": "VON ORMY"
        },
        {
            "count": 1,
            "city": "WHITE SETTLEMENT"
        },
        {
            "count": 56,
            "city": "WINDCREST"
        },
        {
            "count": 1,
            "city": "YANCEY"
        }
    ],
    //------------------------------------------------------------------------------------------------------


};
export default cities;
