import React, { useEffect, useState, useContext } from 'react';
import {
    TextField,
    Grid
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import Payment from './Payment';

import { AppContext } from '../../../App';
import axios from 'axios';
import pageData from '../../../pageData';
import states from '../../../states';

const CompleteRegistration = ({ className, ...rest }) => {
    const [user] = useContext(AppContext);
    const userData = JSON.parse(user);

    const [openPhone, setOpenPhone] = useState(false);
    const [savedPhone, setSavedPhone] = useState(false);
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');

    const [openMembership, setOpenMembership] = useState(false);
    const [activeMembership, setActiveMembership] = useState(false);

    const [value, setValue] = useState('PayAsGo');
    const [address, setAddress] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [name, setName] = useState('');

    const [openTwilioPhoneNumber, setOpenTwilioPhoneNumber] = useState(false);
    const [twilioState, setTwilioState] = useState('');
    const [twilioAreaCode, setTwilioAreaCode] = useState([]);
    const [twilioSelectedAreaCode, setTwilioSelectedAreaCode] = useState('');
    const [twilioAvailableNumbers, setTwilioAvailableNumbers] = useState([]);
    const [twilioSelectedPhoneNumber, setTwilioSelectedPhoneNumber] = useState('');

    const fetchPhoneNumber = async () => {
        await axios.get(`${pageData.apiUrl}/api/get-phone-number/${userData.userId}`)
            .then((res) => {
                if (res.status === 200) {
                    if (!res.data.user.phone || !res.data.user.validatedPhone) {
                        if (res.data.user.phone) {
                            setSavedPhone(true);
                            fetchMembership();
                        }
                        handleOpenPhone();
                    } else {
                        fetchMembership();
                    }
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        fetchPhoneNumber();
    }, []);

    const handleOpenPhone = () => {
        setOpenPhone(true);
    };

    const handleClosePhone = () => {
        setOpenPhone(false);
    };

    const handleChangePhone = (event) => {
        let tempPhone = event.target.value;
        setPhone(`${tempPhone.substring(0, 3)} ${tempPhone.substring(3, 7)} ${tempPhone.substring(7, tempPhone.length)}`.replace(/  /gm, ' ').trim());
    };

    const handleChangeCode = (event) => {
        setCode(event.target.value);
    };

    const handleUpdatePhoneNumber = async () => {
        const userInfo = new FormData();
        userInfo.append('userId', userData.userId);
        userInfo.append('phone', phone);

        await axios.post(`${pageData.apiUrl}/api/update-phone-number`, userInfo)
            .then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        fetchPhoneNumber();
                    }, 50)
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleValidateCode = async () => {
        const userInfo = new FormData();
        userInfo.append('userId', userData.userId);
        userInfo.append('code', code);

        await axios.post(`${pageData.apiUrl}/api/validate-phone-code`, userInfo)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.success) {
                        ToastsStore.success(res.data.message);
                        setTimeout(() => {
                            fetchPhoneNumber();
                            setSavedPhone(true);
                            handleClosePhone();
                        }, 50)
                    } else {
                        ToastsStore.error(res.data.message);
                    }
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleResendVerificationCode = async () => {
        const userInfo = new FormData();
        userInfo.append('userId', userData.userId);

        await axios.post(`${pageData.apiUrl}/api/resend-verification-code`, userInfo)
            .then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        fetchPhoneNumber();
                    }, 50)
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpenMembership = () => {
        setOpenMembership(true);
    };

    const handleCloseMembership = () => {
        setOpenMembership(false);
        fetchMembership();
    };

    const fetchMembership = async () => {
        await axios.get(`${pageData.apiUrl}/api/get-membership/${userData.userId}`)
            .then((res) => {
                if (res.status === 200) {
                    if (!res.data.user.activeMembership) {
                        handleOpenMembership();
                    } else {
                        fetchTwilioPhone();
                    }
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    };

    const handleChangeUnitNumber = (event) => {
        setUnitNumber(event.target.value);
    };

    const handleChangeCity = (event) => {
        setCity(event.target.value);
    };

    const handleChangeState = (event) => {
        setState(event.target.value);
    };

    const handleChangeZipCode = (event) => {
        setZipCode(event.target.value);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleOpenTwilioPhone = () => {
        setOpenTwilioPhoneNumber(true);
    };

    const handleCloseTwilioPhone = () => {
        setOpenTwilioPhoneNumber(false);
    };

    const handleChangeTwilioState = (event) => {
        setTwilioState(event.target.value);
        setTwilioAreaCode(states.find(({ initials }) => initials === event.target.value).codes);
    };

    const handleChangeSelectedTwilioAreaCode = (event) => {
        setTwilioSelectedAreaCode(event.target.value);
    };

    useEffect(() => {
        findAvailableNumbers();
     }, [twilioSelectedAreaCode]);

    const fetchTwilioPhone = async () => {
        await axios.get(`${pageData.apiUrl}/api/get-twilio-phone/${userData.userId}`)
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res.data.twilioPhone.twilioPhone)
                    if (!res.data.twilioPhone.twilioPhone.length > 0) {
                        handleOpenTwilioPhone();
                    }
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const findAvailableNumbers = async () => {
        await axios.get(`${pageData.apiUrl}/api/find-available-phone-number/${twilioSelectedAreaCode}`)
            .then((res) => {
                if (res.status === 200) {
                    setTwilioAvailableNumbers(res.data.availablePhones);
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleChangeSelectedTwilioPhoneNumber = (event) => {
        setTwilioSelectedPhoneNumber(event.target.value);
    };

    const handleSelectTwilioPhoneNumber = async () => {
        const userInfo = new FormData();
        userInfo.append('userId', userData.userId);
        userInfo.append('twilioState', twilioState);
        userInfo.append('twilioPhoneNumber', twilioSelectedPhoneNumber);        

        await axios.post(`${pageData.apiUrl}/api/save-twilio-phone-number`, userInfo)
            .then((res) => {
                if (res.status === 200 && res.data.success) {
                    ToastsStore.success(res.data.message);
                    handleCloseTwilioPhone();
                    rest.finishRegistration();
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return (
        <div>
            <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            <Dialog
                open={openPhone}
                disableBackdropClick
            >
                {
                    !savedPhone ?
                        <div>
                            <DialogTitle>Add your Phone Number</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        name="phone"
                                        onChange={handleChangePhone}
                                        required
                                        value={phone}
                                        variant="outlined"
                                        inputProps={{ maxLength: 12 }}
                                        style={{ width: 250 }}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            {phone.length == 12
                                &&
                                <DialogActions>
                                    <Button
                                        onClick={handleUpdatePhoneNumber}
                                        color="primary"
                                        autoFocus
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            }
                        </div>
                        :
                        <div>
                            <DialogTitle>Validation Code</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <TextField
                                        fullWidth
                                        label="Code"
                                        name="code"
                                        type="number"
                                        onChange={handleChangeCode}
                                        required
                                        value={code}
                                        variant="outlined"
                                        inputProps={{ maxLength: 4 }}
                                        style={{ width: 250 }}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleResendVerificationCode}
                                    color="primary"
                                    autoFocus
                                >
                                    Send Another
                                </Button>
                                {code.length == 4
                                    &&
                                    <Button
                                        onClick={handleValidateCode}
                                        color="primary"
                                        autoFocus
                                    >
                                        Validate
                                    </Button>
                                }
                            </DialogActions>
                        </div>
                }
            </Dialog>

            {!openPhone &&
                (
                    <Dialog
                        open={openMembership}
                        disableBackdropClick
                        fullWidth={true}
                        maxWidth={'md'}
                    >
                        {
                            !activeMembership &&
                            (
                                <div>
                                    <DialogTitle>Select your plan</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <Grid container rowSpacing={1}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Box sx={{ width: '100%' }}>
                                                        <Tabs
                                                            value={value}
                                                            onChange={handleChangeTab}
                                                            textColor="secondary"
                                                            indicatorColor="secondary"
                                                            aria-label="secondary tabs example"
                                                            centered
                                                        >
                                                            <Tab value="PayAsGo" label="Pay As You Go" />
                                                            <Tab value="Premium" label="Premium" />
                                                        </Tabs>
                                                    </Box>
                                                    {
                                                        value === 'PayAsGo' ?
                                                            (
                                                                <div style={{ margin: 15 }}>
                                                                    <ul>
                                                                        <li><strong>One</strong> local phone number from the area code of your preference.</li>
                                                                        <li><strong>20</strong> free daily <strong>leads</strong> included from almost 1 millions of properties validated in our system.</li>
                                                                        <li>Included balance to use of <strong>$1.00</strong>.</li>
                                                                    </ul>
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <h1>$5.99 a week</h1>
                                                                    </div>
                                                                    <h4>Extra balance consumpsion:</h4>
                                                                    <div style={{ marginLeft: 25 }}>
                                                                        <ul>
                                                                            <li>Lower as <strong>$0.020 / SMS</strong> send or received.</li>
                                                                            <li>Extra property <strong>$0.01 / LEAD</strong>.</li>
                                                                            <li>Full property detail for <strong>$0.30</strong>.</li>
                                                                            <li>Extra phone number for <strong>$0.50 / week</strong>.</li>
                                                                            <li>Forwarding incoming calls to your standard phone number for <strong>$0.01 / minute</strong></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div style={{ fontSize: 12, marginTop: 8 }}>
                                                                        You balance never expired, and you can refund in case you cancel your subscription.
                                                                    </div>
                                                                </div>
                                                            ) :
                                                            (
                                                                <div style={{ margin: 15 }}>
                                                                    <ul>
                                                                        <li><strong>One</strong> local phone number from the area code of your preference.</li>
                                                                        <li><strong>100</strong> free daily <strong>leads</strong> included from almost 1 millions of properties validated in our system.</li>
                                                                        <li>Included balance to use of <strong>$10.00</strong>.</li>
                                                                    </ul>
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <h1>$15.99 a week</h1>
                                                                    </div>
                                                                    <h4>Extra balance consumpsion:</h4>
                                                                    <div style={{ marginLeft: 25 }}>
                                                                        <ul>
                                                                            <li>Lower as <strong>$0.015 / SMS</strong> send or received.</li>
                                                                            <li>Extra property <strong>$0.005 / LEAD</strong>.</li>
                                                                            <li>Full property detail for <strong>$0.25</strong>.</li>
                                                                            <li>Extra phone number for <strong>$0.50 / week</strong>.</li>
                                                                            <li>Forwarding incoming calls to your standard phone number for <strong>$0.01 / minute</strong></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div style={{ fontSize: 11, marginTop: 15 }}>
                                                                        You balance never expired, and can be refunded in case you cancel your subscription.
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </Grid>

                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    md={6}
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >

                                                    <div style={{ width: '100%' }}>

                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            wrap="wrap"
                                                        >
                                                            <Grid
                                                                item
                                                                md={8}
                                                                sm={8}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Billing Address"
                                                                    margin="normal"
                                                                    name="address"
                                                                    onChange={handleChangeAddress}
                                                                    type="text"
                                                                    value={address}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={4}
                                                                sm={4}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Unit"
                                                                    margin="normal"
                                                                    name="unitNumber"
                                                                    onChange={handleChangeUnitNumber}
                                                                    type="text"
                                                                    value={unitNumber}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            wrap="wrap"
                                                        >
                                                            <Grid
                                                                item
                                                                md={5}
                                                                sm={5}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="City"
                                                                    margin="normal"
                                                                    name="city"
                                                                    onChange={handleChangeCity}
                                                                    type="text"
                                                                    value={city}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={3}
                                                                sm={3}
                                                                xs={12}
                                                            >
                                                                <FormControl style={{ width: '100%', marginTop: 16 }}>
                                                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={state}
                                                                        label="State "
                                                                        onChange={handleChangeState}
                                                                    >
                                                                        {states.map((state) => <MenuItem value={state.initials}>{state.initials}</MenuItem>)}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={4}
                                                                sm={4}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="ZipCode"
                                                                    margin="normal"
                                                                    name="zipCode"
                                                                    onChange={handleChangeZipCode}
                                                                    type="text"
                                                                    value={zipCode}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            wrap="wrap"
                                                        >
                                                            <Grid
                                                                item
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    label="Name on Card"
                                                                    margin="normal"
                                                                    name="name"
                                                                    onChange={handleChangeName}
                                                                    type="text"
                                                                    value={name}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>

                                                        </Grid>

                                                    </div>

                                                    {
                                                        address !== '' &&
                                                        city !== '' &&
                                                        zipCode !== '' &&
                                                        state !== '' &&
                                                        name !== '' &&
                                                        (
                                                            <div >
                                                                <Payment
                                                                    plan={value}
                                                                    userInfo={{
                                                                        address: address,
                                                                        unitNumber: unitNumber,
                                                                        city: city,
                                                                        state: state,
                                                                        zipCode: zipCode,
                                                                        name: name
                                                                    }}
                                                                    paySubscription={() => handleCloseMembership()}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                </div>
                            )
                        }
                    </Dialog>
                )}


            <Dialog
                open={openTwilioPhoneNumber}
                disableBackdropClick
            >
                <div>
                    <DialogTitle>Choose a Local Phone Number</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div>
                                <FormControl style={{ width: 250, marginTop: 16 }}>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={twilioState}
                                        label="State "
                                        onChange={handleChangeTwilioState}
                                    >
                                        {states.map((state) => <MenuItem value={state.initials}>{state.value}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>

                            {
                                twilioAreaCode.length > 0 &&
                                (
                                    <div>
                                        <FormControl style={{ width: 250, marginTop: 16 }}>
                                            <InputLabel id="demo-simple-select-label">Area Code</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={twilioSelectedAreaCode}
                                                label="Area Code "
                                                onChange={handleChangeSelectedTwilioAreaCode}
                                            >
                                                {twilioAreaCode.length > 0 && twilioAreaCode.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )
                            }

                            {
                                twilioAvailableNumbers.length > 0 &&
                                (
                                    <div>
                                        <FormControl style={{ width: 250, marginTop: 16 }}>
                                            <InputLabel id="demo-simple-select-label">Available Numbers</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={twilioSelectedPhoneNumber}
                                                label="Available Numbers "
                                                onChange={handleChangeSelectedTwilioPhoneNumber}
                                            >
                                                {twilioAvailableNumbers.length > 0 && twilioAvailableNumbers.map((item) => <MenuItem value={item.phoneNumber}>{item.friendlyName}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )
                            }

                        </DialogContentText>
                    </DialogContent>
                    {twilioSelectedPhoneNumber !== ''
                        &&
                        <DialogActions>
                            <Button onClick={handleSelectTwilioPhoneNumber} color="primary" autoFocus>
                                Select
                            </Button>
                        </DialogActions>
                    }
                </div>
            </Dialog>
        </div>
    );
};

export default CompleteRegistration;
