import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle
} from '@material-ui/core';
import Page from '../../components/Page';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import axios from 'axios';
import pageData from '../../pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const passwordKey = query.get('key');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/', { replace: true });
  };

  return (
    <Page
      className={classes.root}
      title="Forgot Password"
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().min(5).max(255).required('Password is required')
            })}
            onSubmit={(loginData) => {
              const userData = new FormData();
              userData.append('password', loginData.password);

              axios.post(`${pageData.apiUrl}/userService/password-reset/${passwordKey}`, userData)
                .then((res) => {
                  if (res.status === 200) {
                    if (res.data.success) {
                      handleClickOpen();
                    } else {
                      ToastsStore.error(res.data.message);
                    }
                  }
                })
                .catch((err) => console.log(err));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    New Password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Change Password
                  </Button>
                </Box>
                <Box
                  mb={3}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Back to
                    {'  '}
                    <Link
                      // component={RouterLink}
                      to="/login"
                      variant="h6"
                    >
                      SIGN IN
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Password was successfully changed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ResetView;
