import React from 'react';

const Pointer = () => {
  return (
    <img
      // src="/static/images/pin.png"
      src="http://www.google.com/mapfiles/marker.png"
      alt="property front"
      width="20px"
    />
  );
};

export default Pointer;
