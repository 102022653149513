import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import Tables from './Tables';
import ContactDetails from './ContactDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));

const StatisticListView = () => {
  const classes = useStyles();
  const [contact, setContact] = useState('');

  const handleSelectContact = (contactSelected) => {
    setContact(contactSelected);
  };

  const closeSelected = () => {
    setContact('');
  };

  return (
    <Page
      className={classes.root}
      title="Find It Easy - All Contacts"
    >
      <Container maxWidth={false}>
        {contact === '' ? (
          <Box>
            <Tables selectContact={(contactSelected) => handleSelectContact(contactSelected)} />
          </Box>
        )
          : (
            <Box>
              <ContactDetails contact={contact} closeContact={() => closeSelected()} />
            </Box>
          )}
      </Container>
    </Page>
  );
};

export default StatisticListView;
