import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  makeStyles,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { AppContext } from '../../../App';
import axios from 'axios';
import pageData from '../../../pageData';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  },
  positiveValue: {
    color: colors.green[900]
  },
  negativeValue: {
    color: colors.red[900]
  }
}));

const AccountBalance = ({ className, ...rest }) => {
  const classes = useStyles();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);

  const [balance, setBalance] = useState(0);

  const [openPayment, setOpenPayment] = useState(false);
  const [payment, setPayment] = React.useState(20);

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  const fetchProfitLatest = async () => { 
    rest.refreshAccount();   
    await axios.get(`${pageData.apiUrl}/api/get-account-balance/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          if(res.data.user.balance){
            setBalance(res.data.user.balance.toFixed(2));
            rest.refreshAccount();
          }          
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  if(rest.data){
    fetchProfitLatest();  
  }

  useEffect(() => {
    fetchProfitLatest();    
  }, []);

  const handleOpenPayment = () => {
    setOpenPayment(true);
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  const handleAddBalance = async (req) => {
    const userInfo = new FormData();
    userInfo.append('userId', userData.userId);
    userInfo.append('balance', payment);

    await axios.post(`${pageData.apiUrl}/api/make-payment`, userInfo)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {         
          if (res.data.success) {
            handleClosePayment();
            fetchProfitLatest();
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return (
    <div>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            justify="space-between"
            spacing={1}
          >
            <Grid item >
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                ACCOUNT BALANCE
              </Typography>
              <Box
                ml={5}
                mt={2}
              >
                <Typography
                  variant="h2"
                >
                  $
                  {balance}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <AttachMoneyIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box
            mt={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Button
              onClick={handleOpenPayment}
              color="primary"
            >
              Add Balance
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Dialog
        open={openPayment}
        disableBackdropClick
      >
        <DialogTitle>Add Balance</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Balance</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={payment}
                label="Balance"
                onChange={handleChangePayment}
                style={{ width: 300 }}
              >
                <MenuItem value={20}>$20.00</MenuItem>
                <MenuItem value={50}>$50.00</MenuItem>
                <MenuItem value={100}>$100.00</MenuItem>
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'red' }}
            onClick={handleClosePayment}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={handleAddBalance}
          >
            {`Pay $${(payment + (payment * 0.07)).toFixed(2)}`}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

AccountBalance.propTypes = {
  className: PropTypes.string
};

export default AccountBalance;
