const pageData = {
  apiUrl: 'https://finditeasy.us:7000',
  googleAPI: 'AIzaSyBbfrw0oPWsx6_3r3BaHoWhmMMi6e73YXA',
  name: 'Find It Easy',
  appLogo: '/img/full-logo-white.png',
  logoAlt: 'Find It Easy logo',
  privacyUpdate: '04/22/2020',
  freetrial: 'Get started with 10 days of free services.'
};
export default pageData;
