import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
  Box,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';

const PropertyValue = ({ className, ...rest }) => {
  const theme = useTheme();

  let lastSale = 0;
  if (rest.property.lastSaleAmount > 0) {
    lastSale = rest.property.lastSaleAmount;
  }
  const estimateValue = rest.property.estValue;
  let lienAmount = 0;
  if (parseInt(rest.property.lienAmount, 10) > 0) {
    lienAmount = parseInt(rest.property.lienAmount, 10);
  }
  const remainingBalance = rest.property.estRemainingBalanceOfOpenLoans;
  const estEquity = estimateValue - lienAmount - remainingBalance;

  const data = {
    datasets: [
      {
        data: [estEquity, remainingBalance, lienAmount],
        backgroundColor: [
          '#1e88e5',
          colors.red[600],
          '#673ab7'
        ],
        borderWidth: 4,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Est Equity', 'Remaining Balance', 'Liens Amount']
  };

  const options = {
    animation: false,
    cutoutPercentage: 65,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const profitData = {
    datasets: [
      {
        label: 'Purchase Profit',
        data: [lastSale, estimateValue, estimateValue - lastSale],
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          estimateValue > lastSale ? 'rgba(75, 192, 192, 0.2)' : 'rgba(255, 99, 132, 0.2)'
        ],
        borderWidth: 1,
        borderColor: [
          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
          estimateValue > lastSale ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)'
        ]
      }
    ],
    labels: ['Last Sale', 'Est Value', 'Est Profit']
  };

  const profitOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false
          }
        },
      ],
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
  };

  const values = [
    {
      title: 'Equity',
      value: ((estEquity * 100) / (estEquity + remainingBalance + lienAmount)).toFixed(0),
      color: '#1e88e5'
    },
    {
      title: 'Balance',
      value: ((remainingBalance * 100) / (estEquity + remainingBalance + lienAmount)).toFixed(0),
      color: colors.red[600]
    },
    {
      title: 'Liens',
      value: ((lienAmount * 100) / (estEquity + remainingBalance + lienAmount)).toFixed(0),
      color: '#673ab7'
    }
  ];

  return (
    <div>
      <Box
        height={170}
        position="relative"
      >
        <Doughnut
          data={data}
          options={options}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        mt={1}
      >
        {values.map(({
          color,
          title,
          value
        }) => (
          <Box
            key={title}
            p={1}
            textAlign="center"
          >
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {title}
            </Typography>
            <Typography
              style={{ color }}
              variant="h3"
            >
              {value}
              %
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider style={{ marginBottom: '10px' }} />
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h5"
      >
        Purchase Profit
      </Typography>
      <Box
        height={380}
        width="80%"
        marginLeft="10%"
        position="relative"
        mt={1}
      >
        <Bar
          data={profitData}
          options={profitOptions}
          height="height: 100 !important"
        />
      </Box>
    </div>
  );
};

PropertyValue.propTypes = {
  className: PropTypes.string
};

export default PropertyValue;
