import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 700
  },
  imageMobile: {
    marginTop: 30,
    display: 'inline-block',
    maxWidth: '100%',
    width: '100%'
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  const [width] = useState(window.innerWidth);

  return (
    <Page
      className={classes.root}
      title="404"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant={width > 500 ? 'h1' : 'h4'}
          >
            404: The page you are looking for does not exist
          </Typography>
          <Box textAlign="center">
            {width > 500
              ? (
                <img
                  alt="Under development"
                  className={classes.image}
                  src="/static/images/not-found.png"
                />
              )
              : (
                <img
                  alt="Under development"
                  className={classes.imageMobile}
                  src="/static/images/not-found.png"
                />
              )}
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
